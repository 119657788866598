import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Card, CardHeader, CardBody } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AddVendor from '../commanpage/AddVendor';
import Categories1 from '../commanpage/Categories';
import Attribute from '../commanpage/Attribute';
import Addrowmaterial from '../commanpage/Addrowmaterial';

const AddProduct = () => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [rawData, setRawData] = useState([]);
    const [attData, setAttData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [venderlist, setVenderlist] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const [name, setName] = useState('');
    const [gst, setGst] = useState('');
    const [pro_qty, setQty] = useState('');
    const [pro_unit, setUnit] = useState('');
    const [hsn_no, setHsnNo] = useState('');
    const [man_amount, setManAmount] = useState('');
    const [sale_man_amount, setSaleManAmount] = useState('');
    const [manufacture, setManufacture] = useState('Trading');
    const [costPrice, setCostPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [errors, setErrors] = useState({});
    const [totalCostPrice, setTotalCostPrice] = useState(0);
    const [totalSalePrice, setTotalSalePrice] = useState(0);

    const [modalState, setModalState] = useState({
        addVendor: false,
        addCategory: false,
        addAttribute: false,
        addAttributebutton: false,
        lgShow: false,
    });

    const [newAttributeName, setNewAttributeName] = useState('');
    const [newRowmaterialName, setNewRowmaterialName] = useState('');
    const [newRowmaterialValue, setNewRowmaterialValue] = useState('');

    const [values, setValues] = useState({
        aatNumberValues: [],
        aatQtyValues: [],
        unitCostPrice: [],
        unitSalePrice: [],
        costPrices: [],
        salePrices: [],
        selectedVendorId: [],
    });

    const filteredRawData = rawData && rawData.filter(item => parseInt(item.category_id) === parseInt(selectedCategoryId));
    const filteredAttData = attData && attData.filter(item => parseInt(item.category_id) === parseInt(selectedCategoryId));

    useEffect(() => {
        const calculateTotals = () => {
            let costPriceSum = 0;
            let salePriceSum = 0;

            filteredRawData.forEach((item, index) => {
                const aatQtyValue = values.aatQtyValues[index] || 0;
                const costPrice = values.unitSalePrice[index] || 0;
                const costPrice1 = values.unitCostPrice[index] || 0;

                costPriceSum += aatQtyValue * costPrice1;
                salePriceSum += aatQtyValue * costPrice;
            });

            const gstAmount = (salePriceSum * gst) / 100;
            const salePriceWithGst = salePriceSum + gstAmount;
            console.warn(salePriceWithGst);

            setTotalCostPrice(costPriceSum);
            setTotalSalePrice(salePriceWithGst);
        };

        calculateTotals();
    }, [filteredRawData && filteredRawData, values, gst]);



    const handleInputChange = (setter, errorKey) => (e) => {
        setter(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: false }));
    };


    const handleArrayChange = (key) => (index) => (e) => {
        setValues((prevValues) => {
            const newValues = [...prevValues[key]];
            newValues[index] = e.target.value;
            return { ...prevValues, [key]: newValues };
        });
    };

    const validateFields = (fields) => {
        let isValid = true;
        const newErrors = {};
        fields.forEach(({ value, key }) => {
            if (!value) {
                newErrors[key] = true;
                isValid = false;
            }
        });
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (url, requestData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/productmodule');
                    successCallback && successCallback(response.data.payload);
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitProduct = () => {
        if (
            validateFields([
                { value: name, key: 'name' },
                { value: selectedCategoryId, key: 'selectedCategoryId' },
                { value: gst, key: 'gst' },
                { value: pro_qty, key: 'pro_qty' },
                { value: pro_unit, key: 'pro_unit' },
                // { value: totalCostPrice, key: 'costPrice' },
                // { value: totalSalePrice, key: 'salePrice' },
            ])
        ) {
            // Prepare payload for attributes
            let payload = [];
            if (manufacture === 'Trading') {
                payload = filteredAttData.map((item, index) => ({
                    id: item.id,
                    name: item.name,
                    value: values.aatNumberValues[index],
                }));
            }

            // Prepare payload for raw materials with salePriceSum included
            let payload1 = [];
            if (manufacture === 'Trading') {
                payload1 = filteredRawData.map((item, index) => {
                    const aatQtyValue = values.aatQtyValues[index];
                    const unitSalePrice = values.unitSalePrice[index];
                    const salePriceSum = aatQtyValue * unitSalePrice;

                    const unitCostPrice = values.unitCostPrice[index];
                    const costPriceSum = aatQtyValue * unitCostPrice;

                    const gstAmount = (salePriceSum * gst) / 100;
                    const salePriceWithGst = salePriceSum + gstAmount;

                    return {
                        id: item.id,
                        raw_name: item.name,
                        unit_type: item.unit_type,
                        qty: aatQtyValue,
                        vender_id: values.selectedVendorId[index] || 0,
                        u_cost_prize: unitCostPrice,
                        unit_sale_price: values.unitSalePrice[index] || 0,
                        Costprice: costPriceSum,
                        Saleprice: salePriceWithGst,
                    };
                });
            }

            // Call handleSubmit function to send data to API
            handleSubmit(
                `${process.env.REACT_APP_API_URL}product-store`,
                {
                    category_id: parseInt(selectedCategoryId),
                    attributes: payload,
                    Row_met: payload1,
                    name: name,
                    gst: gst,
                    pro_unit: pro_unit,
                    hsn_no: hsn_no,
                    pro_qty: pro_qty,
                    man_amount: man_amount,
                    sale_man_amount: sale_man_amount,
                    manufacture: manufacture,
                    cost_price: totalCostPrice,
                    sale_price: totalSalePrice,
                    type: 'add_product',
                },
                () => {
                    // Clear form fields and state after successful submission
                    setSelectedCategoryId('');
                    setName('');
                    setGst('');
                    setQty('');
                    setUnit('');
                    setHsnNo('');
                    setManAmount('');
                    setSaleManAmount('');
                    setCostPrice('');
                    setSalePrice('');
                    setManufacture(''); // Clear the manufacture state

                    setValues({
                        aatNumberValues: [],
                        aatQtyValues: [],
                        unitCostPrice: [],
                        unitSalePrice: [],
                        costPrices: [],
                        salePrices: [],
                        selectedVendorId: [],
                    });
                }
            );
        } else {
            setLoading(false); // Ensure loading state is handled properly
        }
    };


    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setter(response.data.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // useEffect(() => {
    //     const fetchCategories = async () => {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'x-access-token': token,
    //                 },
    //             });
    //             setCategories(response.data.data || []);
    //         } catch (error) {
    //             console.error('Error fetching categories:', error);
    //         }
    //     };
    //     if (!modalState.addCategory) {
    //         fetchCategories();
    //     }
    // }, [token, modalState.addCategory]);

    useEffect(() => {
        if (!modalState.addCategory) {
            fetchData(
                `${process.env.REACT_APP_API_URL}category-list`,
                setCategories,
              
            );
        }
        if (!modalState.addVendor) {
            fetchData(
                `${process.env.REACT_APP_API_URL}Vender-list`,
                setVenderlist,
                // setLoadingVendors,
                // setErrorVendors
            );
        }
    }, [modalState.addCategory, modalState.addVendor]);

    useEffect(() => {
        if (selectedCategoryId) {
            fetchData(`${process.env.REACT_APP_API_URL}RawMaterial-list`, setRawData);
            fetchData(`${process.env.REACT_APP_API_URL}attribute-list`, setAttData);
        }
    }, [selectedCategoryId]);

    const handleAddAttribute = () => {
        setAttData((prevAttData) => [
            ...prevAttData,
            { id: prevAttData.length + 1, name: newAttributeName, category_id: selectedCategoryId }
        ]);
        setModalState({ ...modalState, addAttribute: false });
        setNewAttributeName('');
    };
    const handleAddRowmaterial = () => {
        // Assuming filteredRawData is part of your state
        setRawData((prevData) => [
            ...prevData,
            {
                id: prevData.length + 1,
                name: newRowmaterialName,
                // value: newRowmaterialValue,
                category_id: selectedCategoryId,
                unit_type: newRowmaterialValue, // Replace with the actual unit type value
            }
        ]);

        // Reset inputs and close modal
        setModalState({ ...modalState, lgShow: false });
        setNewRowmaterialName('');
        setNewRowmaterialValue('');
    };



    return (
        <div>
            <Container fluid>
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="card-title mb-0">Add Product</h3>
                            <div className="d-flex gap-2">
                                <Button className="btn btn-added d-flex gap-2 align-items-center" onClick={() => setModalState({ ...modalState, addCategory: true })}>
                                    <FaPlus /> Add Product Category
                                </Button>
                                <Button className="btn btn-added d-flex gap-2 align-items-center" onClick={() => setModalState({ ...modalState, addAttributebutton: true })}>
                                    <FaPlus /> Add Attribute
                                </Button>
                                <Button className="btn btn-added d-flex gap-2 align-items-center" onClick={() => setModalState({ ...modalState, addVendor: true })}>
                                    <FaPlus /> Add Vendor
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="productName">
                                        <Form.Label className="custom-label">Enter Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={handleInputChange(setName, 'name')}
                                            className={errors.name ? 'is-invalid' : ''}
                                        />
                                        {/* {errors.name && <Form.Text className="text-danger">Name is required.</Form.Text>} */}
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="productCategory">
                                        <Form.Label className="custom-label">Select Category</Form.Label>
                                        <Form.Select
                                            className={errors.selectedCategoryId ? 'is-invalid' : ''}
                                            value={selectedCategoryId}
                                            onChange={handleInputChange(setSelectedCategoryId, 'selectedCategoryId')}
                                        >
                                            <option value="">Select Category Name</option>
                                            {categories.length > 0 ? (
                                                categories && categories.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="">No Categories Available</option>
                                            )}
                                        </Form.Select>
                                        {/* {errors.selectedCategoryId && <Form.Text className="text-danger">Category is required.</Form.Text>} */}
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="productGst">
                                        <Form.Label>GST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            className={errors.gst ? 'is-invalid' : ''}
                                            placeholder="GST"
                                            value={gst}
                                            onChange={handleInputChange(setGst, 'gst')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="productqty">
                                        <Form.Label>Qty</Form.Label>
                                        <Form.Control
                                            type="number"
                                            className={errors.pro_qty ? 'is-invalid' : ''}
                                            placeholder="Qty"
                                            value={pro_qty}
                                            onChange={handleInputChange(setQty, 'pro_qty')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="productunit">
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className={errors.pro_ ? 'is-invalid' : ''}
                                            placeholder="Unit"
                                            value={pro_unit}
                                            onChange={handleInputChange(setUnit, 'pro_unit')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="productunit">
                                        <Form.Label>Hsn No</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Hsn No"
                                            value={hsn_no}
                                            onChange={handleInputChange(setHsnNo, 'hsn_no')}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3 mt-3" controlId="formGroupEmail">
                                        <div className="d-flex justify-content-between">
                                            {/* <Form.Label htmlFor="inputPassword5">Product Name</Form.Label> */}
                                            <div className="d-flex gap-2 align-items-center">
                                                <Form.Check
                                                    type="radio"
                                                    label="Trading"
                                                    name="addproduct"
                                                    id="addproduct"
                                                    value="Trading"
                                                    className={errors.manufacture ? 'is-invalid' : ''}
                                                    onChange={handleInputChange(setManufacture, 'manufacture')}
                                                    defaultChecked={manufacture === 'Trading'}
                                                />
                                                <Form.Check
                                                    name="addproduct"
                                                    type="radio"
                                                    label="Manufacture"
                                                    id="addproduct2"
                                                    value="Manufacture"
                                                    className={errors.manufacture ? 'is-invalid' : ''}
                                                    onChange={handleInputChange(setManufacture, 'manufacture')}
                                                    defaultChecked={manufacture === 'Manufacture'}

                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {manufacture == 'Manufacture' ? (
                                    <Col md={4}>
                                        <Form.Group className="mb-3" controlId="productunit">
                                            <Form.Label>Cost Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                // className={errors.pro_ ? 'is-invalid' : ''}
                                                placeholder="Cost Amount"
                                                value={man_amount}
                                                onChange={handleInputChange(setManAmount, 'man_amount')}
                                            />
                                        </Form.Group>
                                    </Col>
                                ) : null}
                                {manufacture == 'Manufacture' ? (
                                    <Col md={4}>
                                        <Form.Group className="mb-3" controlId="productunit">
                                            <Form.Label>Sale Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                // className={errors.pro_ ? 'is-invalid' : ''}
                                                placeholder="Sale Amount"
                                                value={sale_man_amount}
                                                onChange={handleInputChange(setSaleManAmount, 'sale_man_amount')}
                                            />
                                        </Form.Group>
                                    </Col>
                                ) : null}
                            </Row>
                        </Form>
                    </div>
                </div>
                {manufacture == 'Trading' ? (
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">Raw Material</h5>
                                <Button onClick={() => setModalState({ ...modalState, lgShow: true })}><FaPlus />   Add More</Button>
                            </div>

                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr className="thead-dark">
                                            <th>Name</th>
                                            <th>Unit</th>
                                            <th>Vendor</th>
                                            <th>QTY</th>
                                            <th>Unit Cost Price</th>
                                            <th>Unit Sale Price</th>
                                            <th>Cost Price</th>
                                            <th>Sale Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRawData && filteredRawData.length > 0 ? (
                                            filteredRawData && filteredRawData.map((item, index) => {
                                                const aatQtyValue = values.aatQtyValues[index];
                                                const unitSalePrice = values.unitSalePrice[index];
                                                const salePriceSum = aatQtyValue * unitSalePrice;

                                                const unitCostPrice = values.unitCostPrice[index];
                                                const costPriceSum = aatQtyValue * unitCostPrice;

                                                const gstAmount = (salePriceSum * gst) / 100;
                                                const salePriceWithGst = salePriceSum + gstAmount;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Form.Group controlId={`productRawMaterial${index}`}>
                                                                <Form.Control readOnly defaultValue={item.name} disabled />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productUnitType${index}`}>
                                                                <Form.Control readOnly defaultValue={item.unit_type} disabled />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productVendor${index}`}>
                                                                <Form.Select
                                                                    style={{ width: "120px" }}
                                                                    onChange={handleArrayChange('selectedVendorId')(index)}
                                                                >
                                                                    <option value="">Select Vendor Name</option>
                                                                    {venderlist.map((vendor) => (
                                                                        <option key={vendor.id} value={vendor.id}>
                                                                            {vendor.vendor_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productQty${index}`}>
                                                                <Form.Control
                                                                    type="number"
                                                                    value={aatQtyValue}
                                                                    onChange={handleArrayChange('aatQtyValues')(index)}
                                                                />
                                                            </Form.Group>
                                                        </td>

                                                        <td>
                                                            <Form.Group controlId={`productCostPrice${index}`}>
                                                                <Form.Control
                                                                    type="number"
                                                                    // value={values.unitCostPrice[index] || ''}
                                                                    value={unitCostPrice}
                                                                    onChange={handleArrayChange('unitCostPrice')(index)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productSalePrice${index}`}>
                                                                <Form.Control
                                                                    type="number"
                                                                    // value={values.unitSalePrice[index] || ''}
                                                                    value={unitSalePrice}
                                                                    onChange={handleArrayChange('unitSalePrice')(index)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productCostPriceSum${index}`}>
                                                                <Form.Control
                                                                    className='graycolorall'
                                                                    type="number"
                                                                    // value={values.costPrices[index] || ''}
                                                                    value={costPriceSum}
                                                                    disabled
                                                                    onChange={handleArrayChange('costPrices')(index)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productSalePriceSum${index}`}>
                                                                <Form.Control
                                                                    className='graycolorall'
                                                                    type="number"
                                                                    value={salePriceWithGst || values.salePrices}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="12" className='text-center'>
                                                    <span>Select Category Name</span>
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </CardBody>

                    </Card>
                ) : null}
                {manufacture == 'Trading' ? (
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between">
                                <h5 className="card-title mb-0">Attributes</h5>
                                <Button className="btn btn-added d-flex gap-2 align-items-center" onClick={() => setModalState({ ...modalState, addAttribute: true })}>
                                    <FaPlus /> Add More
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr className="thead-dark">
                                                <th>Attribute</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredAttData && filteredAttData.length > 0 ? (
                                                filteredAttData && filteredAttData.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Form.Group controlId={`productAttribute${index}`}>
                                                                <Form.Control readOnly defaultValue={item.name} disabled />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`productAttributeValue${index}`}>
                                                                <Form.Control
                                                                    type="number"
                                                                    value={values.aatNumberValues[index] || ''}
                                                                    onChange={handleArrayChange('aatNumberValues')(index)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="12" className='text-center'>
                                                        <span>Select Category Name</span>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </>


                        </CardBody>
                    </Card>
                ) : null}
                <Card>
                    <CardBody>

                        {manufacture == 'Trading' ? (
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="costPrice">
                                        <Form.Label className="custom-label">Total Cost Price</Form.Label>
                                        <Form.Control
                                            className='graycolorall'
                                            type="number"
                                            placeholder="Total Cost Price"
                                            value={totalCostPrice}
                                            readOnly
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="salePrice">
                                        <Form.Label className="custom-label">Total Sale Price</Form.Label>
                                        <Form.Control
                                            className='graycolorall'
                                            type="number"
                                            placeholder="Total Sale Price"
                                            value={totalSalePrice}
                                            readOnly
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        ) : null}
                    </CardBody>

                </Card>
                <Row className="mt-3">
                    <Col md={{ span: 4, offset: 8 }}>
                        <div className="d-flex justify-content-end">
                            <Button
                                type="button"
                                onClick={handleSubmitProduct}
                                disabled={loading}
                                className="btn btn-primary"
                            >
                                {loading ? 'Saving...' : 'Create Product'}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
            {/* <Modal
                size="lg"
                show={modalState.lgShow}
                onHide={() => setModalState({ ...modalState, lgShow: false })}
                aria-labelledby="example-modal-sizes-title-lg"
            >

                <Modal.Body>
                    <Addrowmaterial onSave={() => setModalState({ ...modalState, lgShow: false })} />
                </Modal.Body>
            </Modal> */}


            <Modal show={modalState.lgShow} onHide={() => setModalState({ ...modalState, lgShow: false })}
                data-backdrop="static" data-keyboard="false">
                {/* show={showAddModal} onHide={handleCloseAddModal} */}
                <Modal.Header closeButton>
                    <Modal.Title>Add Row Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Enter Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        value={newRowmaterialName}
                                        onChange={(e) => setNewRowmaterialName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Enter Unit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Unit"
                                        value={newRowmaterialValue}
                                        onChange={(e) => setNewRowmaterialValue(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="primary" className="btn btn-submit" onClick={handleAddRowmaterial}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalState.addCategory}
                onHide={() => setModalState({ ...modalState, addCategory: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <Categories1 onSave={() => setModalState({ ...modalState, addCategory: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addVendor}
                onHide={() => setModalState({ ...modalState, addVendor: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <AddVendor onSave={() => setModalState({ ...modalState, addVendor: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addAttributebutton}
                onHide={() => setModalState({ ...modalState, addAttributebutton: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <Attribute onSave={() => setModalState({ ...modalState, addAttributebutton: false })} />
                </Modal.Body>
            </Modal>

            <Modal show={modalState.addAttribute} onHide={() => setModalState({ ...modalState, addAttribute: false })} backdrop="static"
                keyboard={false}>
                {/* show={showAddModal} onHide={handleCloseAddModal} */}
                <Modal.Header closeButton>
                    <Modal.Title>Add Attribute Row</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Enter Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        value={newAttributeName}
                                        onChange={(e) => setNewAttributeName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="primary" className="btn btn-submit" onClick={handleAddAttribute}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal
                show={modalState.addAttribute}
                onHide={handleModalClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body>
                    <Attribute onSave={() => setModalState({ ...modalState, addAttribute: false })} />
                </Modal.Body>
            </Modal> */}

        </div>
    );
};

export default AddProduct;
