import React, { useState, useMemo, useEffect } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowRight, MdKeyboardArrowLeft, MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiUser2Fill } from "react-icons/ri";
import AddEmployee from './AddEmployee';
import Employeeview from './Employeeview';
import EmployeeUpdate from './EmployeeUpdate';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import { FaEye } from "react-icons/fa";

const Stocktable = () => {
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addEmployeeEditModal, setAddEmployeeEditModal] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const token = localStorage.getItem('token');
  const [modalState, setModalState] = useState({ Expenditureview: false });


  useEffect(() => {
    fetchEmployeeList();
  }, [addProductModal, addEmployeeEditModal]);

  const fetchEmployeeList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Employee-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setEmployeeList(response.data.data || []);
      setTotalEmployees(response.data.data.length || []);
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  };

  const handleDeleteEmployee = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}Employee-delete/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchEmployeeList();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("There was a problem with the delete operation:", error);
    }
  };

  const handleEditEmployee = (id) => {
    setAddEmployeeEditModal(true);
    localStorage.setItem('employee_id', id);
  };
  const handleviewEmployee = (id) => {
    setModalState({ ...modalState, Expenditureview: true })
    localStorage.setItem('employee_view_id', id)
  }

  const data = useMemo(
    () => employeeList.map((employee, index) => ({
      srnumber: index + 1,
      Name: employee.emp_name,
      Designation: employee.designation,
      Role: employee.role,
      Mobile: employee.mobile_number,
      Salayr: employee.salayr,
      Address: employee.address,
      Status: employee.status,
      Balance: employee.salary,
      Action: (
        <div className='edit-delete-action d-flex gap-2'>
           <div className="me-2 p-2 deletebtn" onClick={() => handleviewEmployee(employee.id)}>
            <FaEye />
          </div>
          <div className="me-2 p-2 editbutton" onClick={() => handleEditEmployee(employee.id)}>
            <FaEdit />
          </div>
          <Button style={{ "--i": "red" }} onClick={() => handleDeleteEmployee(employee.id)}>
            <MdDelete />
          </Button>
         
        </div>
      ),
    })),
    [employeeList]
  );

  const columns = useMemo(
    () => [
      { Header: 'Sr Number', accessor: 'srnumber' },
      { Header: 'Name', accessor: 'Name' },
      { Header: 'Designation', accessor: 'Designation' },
      { Header: 'Role', accessor: 'Role' },
      { Header: 'Mobile', accessor: 'Mobile' },
      { Header: 'Salayr', accessor: 'Salayr' },
      { Header: 'Address', accessor: 'Address' },
      {
        Header: 'Status',
        accessor: 'Status',
        Cell: ({ value }) => (
          <span style={{ color: value == 1 ? 'green' : 'red' }}>
            {value == 1 ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      { Header: 'Action', accessor: 'Action' },
    ],
    []
  );



  const GlobalFilter = ({ filter, setFilter }) => (
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
      className="form-control"
    />
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Employee list</h5>
            <div className="total-employees">
              <h6>
                <RiUser2Fill className="feather-user" />
                Total Employees <span>{totalEmployees}</span>
              </h6>
            </div>
            <div className="d-flex gap-3">
              <Button variant="primary" onClick={() => setAddProductModal(true)}>Add Employee</Button>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-select d-inline"
                style={{ width: "120px" }}
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
              <div style={{ width: '220px' }}>
                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table {...getTableProps()} className="table datanew">
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                {headerGroups.map((headerGroup) => (
                  <tr className="thead-dark" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
            </div>
            <div className="pagination justify-content-center mt-3">
              <Button variant="link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <MdKeyboardDoubleArrowLeft />
              </Button>
              <Button variant="link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <MdKeyboardArrowLeft />
              </Button>
              <div>
                <h6 className="mb-0 pagination_count d-flex justify-content-center align-items-center">{pageIndex + 1}</h6>
              </div>
              <Button variant="link" onClick={() => nextPage()} disabled={!canNextPage}>
                <MdKeyboardArrowRight />
              </Button>
              <Button variant="link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <MdKeyboardDoubleArrowRight />
              </Button>
            </div>
          </div>
        </Card.Body>

        <Modal
          size="lg"
          show={addProductModal}
          onHide={() => setAddProductModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static" 
          keyboard={false}
        >
          <Modal.Body>
            <AddEmployee onSave={() => setAddProductModal(false)} />
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={addEmployeeEditModal}
          onHide={() => setAddEmployeeEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static" 
          keyboard={false}
        >
          <Modal.Body>
            <EmployeeUpdate onSave={() => setAddEmployeeEditModal(false)} />
          </Modal.Body>
        </Modal>
        <Modal
        show={modalState.Expenditureview}
        // size='lg'
        onHide={() => setModalState({ ...modalState, Expenditureview: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Employee Details</h3>
        </Modal.Header>
        <Modal.Body>
          <Employeeview onSave={() => setModalState({ ...modalState, Expenditureview: false })} />
        </Modal.Body>
      </Modal>
      </Card>
    </>
  );
};

export default Stocktable;
