import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Container, Card } from 'react-bootstrap';
import DataTable from './Stocktable';
import axios from 'axios';

const StockManagement = () => {
    const token = localStorage.getItem('token');
    const account_ladger_id = localStorage.getItem('account_ladger_id');
    const [loading, setLoading] = useState(false);
    const [data, setVenderlist] = useState([]);

    useEffect(() => {
        fetchVendor();

    }, [token]);

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-ladger-list/${account_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setVenderlist(response.data.data || []);
        } catch (error) {
            console.error('Error fetching vendors:', error);
            // toast.error('Failed to fetch vendors.');
        }
    };

    // Define columns
    const columns = useMemo(
        () => [
            {
                Header: 'Sr.No',
                accessor: 'Srno',
                Cell: (row) => {
                    return row.row.index + 1;
                },
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-GB');  // 'en-GB' gives DD-MM-YYYY format
                }
            },
            {
                Header: 'Account Name',
                accessor: 'account_from_name',
            },
            // {
            //     Header: 'Product Name & GST',
            //     accessor: 'productname', 
            //     Cell: ({ row }) => {
            //         return `${row.original.productname}@: ${row.original.gst}`;
            //     },
            // },
            {
                Header: 'Amount',
                accessor: 'amount',

            },
            {
                Header: 'Type',
                accessor: 'type',

            },
            {
                Header: 'Remark',
                accessor: 'remark',

            },

            // {
            //     Header: 'Deposit',
            //     accessor: 'customer_name',
            //     Cell: ({ row: { original: { customer_name, vendor_name } } }) => {
            //         // Filter out null or undefined values
            //         const values = [customer_name, vendor_name].filter(Boolean);

            //         // Join values with ' || ' separator
            //         return (
            //             <span style={{ color: 'blue' }}>
            //                 {values.join(' || ')}
            //             </span>
            //         );
            //     },
            // },
            {
                Header: 'Transaction',
                accessor: '',
                Cell: ({ row: { original: { type, expenditure_type_name, account_to_name, customer_name, vendor_name } } }) => {
                    if (type === 'withdraw') {
                        const values = [expenditure_type_name, vendor_name, account_to_name].filter(Boolean); // Only include expenditure_type_name
                        return (
                            <span style={{ color: 'red' }}>
                                {values.join(' || ')}
                            </span>
                        );
                    } else if (type === 'deposit') {
                        const values = [customer_name, account_to_name].filter(Boolean); // Include account_to_name for deposit
                        return (
                            <span style={{ color: 'green' }}>
                                {values.join(' || ')}
                            </span>
                        );
                    } else {
                        return <span>No Data</span>;
                    }

                },
            },



            // {
            //     Header: 'Credit',
            //     accessor: '',
            //     Cell: ({ row: { original: { expenditure_type_name, account_to_name } } }) => {
            //         const values = [expenditure_type_name, account_to_name].filter(Boolean);

            //         return (
            //             <span style={{ color: 'green' }}>
            //                 {values.join(' || ')}
            //             </span>
            //         );
            //     },
            // },




        ],
        []
    );
    return (
        <>

            <div>
                <DataTable columns={columns} data={data} />
                {/* Example modal component */}
                <Modal id="add-units" show={false} onHide={() => { }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Your modal content here */}
                        {/* This is just a placeholder, replace it with your actual modal content */}
                        <p>Modal Content</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>

    );
};

export default StockManagement;
