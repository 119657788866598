import React, { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

// Hook to manage form field states and errors
// Hook to manage form field states and errors
const useFormFields = (initialFields) => {

    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {}));

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;
        // Perform validation
        if (key === 'mobileNumber') {
            value = value.replace(/\D/g, '');
            value = value.slice(0, 10);
        }
        if (key === 'bankAccountNumber') {
            value = value.replace(/\D/g, '');
            // value = value.slice(0, 10);
        }
        if (key === 'ifscCode') {
            value = value.toUpperCase();
            value = value.slice(0, 11);
        }
        if (key === 'gst') {
            value = value.toUpperCase();
            value = value.slice(0, 21);
        }
        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};


const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    // Check only vendorName field
    if (!fields.vendorName) {
        newErrors.vendorName = true;
        isValid = false;
    }

    // Update errors state with newErrors object
    setErrors(newErrors);

    return isValid;
};


// Submit function
const handleSubmit = async (url, requestData, successCallback, setLoading, {onSave}) => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        if (response.data.status === 1) {
            toast.success(response.data.message);
            successCallback();
            onSave()
        } else {
            toast.error(response.data.message);
        }
    } catch (error) {
        console.error('Error:', error);
        // toast.error('An error occurred');
    } finally {
        setLoading(false);
    }
};

const VendorForm = ({ token }) => {
    const initialFields = {
        vendorName: '', vendorShortName: '', mobileNumber: '', gst: '',
        cityName: '', state: '', pinCode: '', bankAccountNumber: '',
        accountHolderName: '', ifscCode: '', accountType: '',
        addressLine1: '', addressLine2: ''
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);

    const handleSubmitVendor = () => {
        // Mapping the field names to match the desired payload keys
        const payload = {
            vendor_name: fields.vendorName,
            vendor_short_name: fields.vendorShortName,
            mobile_number: fields.mobileNumber,
            gst: fields.gst,
            city_name: fields.cityName,
            state: fields.state,
            pin_code: fields.pinCode,
            bank_account_number: fields.bankAccountNumber,
            account_holder_name: fields.accountHolderName,
            ifsc_code: fields.ifscCode,
            account_type: fields.accountType,
            address_line1: fields.addressLine1,
            address_line2: fields.addressLine2,
            status: '0'
        };
        

        if (validateFields(fields, setErrors)) {
            handleSubmit(
                `${process.env.REACT_APP_API_URL}Vender-store`,
                payload, // Sending the payload with modified keys
                token,
                () => {
                    setFields(initialFields);
                    
                    // setAddVendor(false);
                },
                setLoading
            );
        } else {
            setLoading(false);
        }
    };


    return (
        <div id="addVendorModal" >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4> Add Vendor</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid >
                    <Row>
                        {Object.keys(initialFields).map((key, index) => (
                            <Col lg={4} sm={6} xs={12} key={index}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">{key
                                        .replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, str => str.toUpperCase())
                                    }</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={key.replace(/([A-Z])/g, ' $1')}
                                        value={fields[key]}
                                        onChange={handleFieldChange(key)}
                                        className={errors[key] ? 'is-invalid' : ''}
                                    />
                                    {/* {errors[key] && <Form.Control.Feedback type="invalid">Please enter {key.replace(/([A-Z])/g, ' $1')}.</Form.Control.Feedback>} */}
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" className="btn btn-submit" onClick={handleSubmitVendor}>
                    Create
                </Button>
            </Modal.Footer>
        </div>
    );
};

export default VendorForm;
