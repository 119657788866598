import React,{useState, useEffect} from 'react'
import { Link ,useLocation } from 'react-router-dom'
import logo  from '../assets/img/logo.png'
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoNotificationsOutline } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [profile,setProfile] = useState(false);
  const Userlogoutprofile = () =>{
    setProfile(prevProfile => !prevProfile);
  }
  const logout = (e) => {
    e.preventDefault();
    // localStorage.removeItem(user_id);
    localStorage.clear();
    navigate('/');
  }
  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    const token = localStorage.getItem("token");
    if (user_id == null) {
     navigate('/');
    };
    if (token == null) {
     navigate('/');
    }
  }, [])
  return (
    <div className="header">
  
    <ul className="nav user-menu justify-content-end">
      <li className="nav-item dropdown nav-item-box">
        <Link
          to="/notifications"
          className="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
        >
          <IoNotificationsOutline />
          <span className="badge rounded-pill">2</span>
        </Link>
        <div className="dropdown-menu notifications">
          hello
        </div>
      </li>
      <li className="nav-item dropdown has-arrow main-drop">
        <Link
          to="javascript:void(0)"
          className="dropdown-toggle nav-link userset"
          onClick={Userlogoutprofile}>
          <span className="user-info">
            <span className="user-detail">
              <span className="user-name">John Smilga</span>
              <span className="user-role">Super Admin</span>
            </span>
          </span>
          {profile &&  (
          <div className="dropdown-menu menu-drop-user show">
         <Link to="javascript:void(0);" className="dropdown-item">
           My Profile
         </Link>
         <Link to="javascript:void(0);" className="dropdown-item">
           Settings
         </Link>
         <Link onClick={logout} type='button' className="dropdown-item">
           Logout
         </Link>
         </div>
         )}
        </Link>
        
       
      </li>
    </ul>
    <div className="dropdown mobile-user-menu">
      <Link
        to="#"
        className="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <MdOutlineKeyboardDoubleArrowLeft />
      </Link>
      <div className="dropdown-menu dropdown-menu-right">
        <Link to="/profile" className="dropdown-item">
          My Profile
        </Link>
        <Link to="/settings" className="dropdown-item">
          Settings
        </Link>
        <Link to="/logout" className="dropdown-item">
          Logout
        </Link>
      </div>
    </div>
  </div>
  )
}
