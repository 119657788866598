import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const VendorChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'basic-bar'
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5], // Show only 5 columns
      labels: {
        show: false // Hide x-axis labels (e.g., year names)
      }
    },
    colors: ['#ba1654'] 
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'series-1',
      data: [30, 40, 35, 50, 49] 
    }
  ]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
          />
        </div>
      </div>
    </div>
  );
};

export default VendorChart;
