import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Row, Col, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { MdCurrencyRupee } from "react-icons/md";
import { CgCalendarDue } from "react-icons/cg";
import { GiReceiveMoney } from "react-icons/gi";
import Addproduct from '../commanpage/Addproduct';
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { GrDocumentPdf } from "react-icons/gr";
import { RiDownloadLine } from "react-icons/ri";



const OrderProductView = () => {
    const [Data, setViewData] = useState({});
    const [totalData, setTotalData] = useState({});
    const [loadingList, setLoadingList] = useState(false);
    const token = localStorage.getItem("token");
    const orderProductId = localStorage.getItem("orderproduct_id");
    const navigate = useNavigate();
    const [modalState, setModalState] = useState({ addproduct: false });


    useEffect(() => {
        const fetchOrderData = async () => {
            setLoadingList(true);
            try {
                const url = `${process.env.REACT_APP_API_URL}Order-view-product-list/${orderProductId}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                const { createOrder, product, extra_charges_store, product_other_data } = response.data.rows;
                // console.warn(product);

                // Assuming createOrder has only one item based on your example
                const orderDetails = createOrder[0];
                // console.warn(orderDetails)

                // setTotalData(orderDetails);
                setViewData({
                    ...orderDetails,
                    products: product,
                    extra_charges_store: extra_charges_store,
                    otherData: product_other_data || []
                });
                console.warn(Data);
            } catch (error) {
                console.error("There was a problem fetching data:", error);
            } finally {
                setLoadingList(false);
            }
        };

        fetchOrderData();
    }, [token]);

    const goBack = () => {
        navigate('/orderview');
    };

    const totalPrice = parseFloat(Data.total_price) || 0;
    const totalLabelCharge = parseFloat(Data.total_lable_charge) || 0;
    const totalamount = parseFloat(Data.amount) || 0;

    const total = totalPrice + totalLabelCharge;
    const totaldue = totalamount - total;

    // useEffect(() => {
    //     const printButton = document.getElementById('printButton');
    //     const handlePrint = () => {
    //         window.print();
    //     };

    //     printButton.addEventListener('click', handlePrint);

    //     return () => {
    //         printButton.removeEventListener('click', handlePrint);
    //     };
    // }, []);


    return (
        <>
            <section className='orderview_section'>

                <div className="page-header">
                    <div className="add-item d-flex justify-content-between align-items-center w-100">
                        <div className="page-title">
                            <h4>Product Details</h4>
                        </div>
                        <div className='d-flex gap-2'>
                            {/* {!buttonsDisabled && ( */}
                            <>
                                {/* <Button type="button" className="btn-added" onClick={() => setModalState({ ...modalState, addproduct: true })}> Add Product</Button> */}
                                {/* <Button type="button">pdf<GrDocumentPdf />  <RiDownloadLine />
                                </Button> */}
                                {/* <Button type="button" onClick={generatePDF}>pdfw<GrDocumentPdf /> <RiDownloadLine /></Button> */}
                                <Button type="button" className="btn-added" onClick={goBack}> <FaArrowLeftLong style={{ paddingRight: '3px' }} />  Back</Button>
                            </>
                            {/* )} */}
                        </div>
                    </div>
                    <div className="page-btn d-flex gap-2"></div>
                </div>
                <Row>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Customer Info</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Cus. Name</h4>
                                    <h5 className="text-muted fs-6">{Data.customer_name}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Cus. Type</h4>
                                    <h5 className="text-muted fs-6">{Data.customer_type}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Mobile</h4>
                                    <h5 className="text-muted fs-6">{Data.mobile_number}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Email</h4>
                                    <h5 className="text-muted fs-6">{Data.email}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Site Name</h4>
                                    <h5 className="text-muted fs-6">{Data.site_name}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Del. Date</h4>
                                    <h5 className="text-muted fs-6">{Data.delivery_date}</h5>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Address</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Pincode</h4>
                                    <h5 className="text-muted fs-6">{Data.pincode}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>City</h4>
                                    <h5 className="text-muted fs-6">{Data.city}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>State</h4>
                                    <h5 className="text-muted fs-6">{Data.state}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Address 1</h4>
                                    <h5 className="text-muted fs-6">{Data.address1}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Address 2</h4>
                                    <h5 className="text-muted fs-6">{Data.address2}</h5>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Account</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Account Name</h4>
                                    <h5 className="text-muted fs-6">{Data.account_name}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Account Mode</h4>
                                    <h5 className="text-muted fs-6">{Data.account_mode}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Amount</h4>
                                    <h5 className="text-muted fs-6">{Data.amount}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 border-bottom py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Other Amount</h4>
                                    <h5 className="text-muted fs-6">{(Data.total_lable_charge?.toFixed(2) ?? '0.00')}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Total Amount</h4>
                                    <h5 className="text-muted fs-6">{(Data.total_amount)}</h5>
                                </div>
                                <div className="d-flex justify-content-between gap-3 py-1">
                                    <h4 className='mb-0 fs-6 fw-bold'>Due Amount</h4>
                                    <h5 className="text-muted fs-6">{(Data.due_amt)}</h5>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {Data.order_type == 'design' ? (
                    <>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Design Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="w-50">

                                    <Table striped hover>
                                        <tbody>
                                            {Data.products && Data.products.map((product, index) => (
                                                <tr key={product.id}>

                                                    <td>{index + 1}</td>
                                                    <td><strong>{product.design_category_name}</strong></td>
                                                    <td dangerouslySetInnerHTML={{ __html: product.design_description }}></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>

                        </Card>
                    </>
                ) : (
                    <>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Product Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table striped hover>
                                    <thead>
                                        <tr className='thead-dark'>
                                            <th>Sr. No.</th>
                                            <th>Product Name</th>
                                            <th>Vendor Name</th>
                                            <th>Unit</th>
                                            <th>Qty</th>
                                            <th>Gst</th>
                                            <th>Price</th>
                                            {/* <th>Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.products && Data.products.map((product, index) => (
                                            <tr key={product.id}>
                                                <td>{index + 1}</td>
                                                <td>{product.name}</td>
                                                <td>{product.vendor_name}</td>
                                                <td>{product.pro_unit}</td>
                                                <td>{product.qty}</td>
                                                <td>{product.gst} %</td>
                                                <td>{product.price}</td>
                                                {/* <td>Processing</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Extra Work</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table striped hover>
                                    <thead>
                                        <tr className='thead-dark'>
                                            <th>Sr. No.</th>
                                            <th>Product Name</th>
                                            <th>Vendor Name</th>
                                            {/* <th>Unit</th> */}
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                            {/* <th>Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.extra_charges_store && Data.extra_charges_store.map((product, index) => (
                                            <tr key={product.id}>
                                                <td>{index + 1}</td>
                                                <td>{product.name}</td>
                                                <td>{product.vendor_name}</td>
                                                {/* <td>{product.pro_unit}</td> */}
                                                <td>{product.pro_qty}</td>
                                                <td>{product.price}</td>
                                                <td>{product.total_amount}</td>
                                                {/* <td>Processing</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Other Charges</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="w-50">

                                    <Table striped hover>
                                        {/* <thead>
                            <tr className='thead-dark'>
                                <th>Sr. No.</th>
                                <th>Transport Charge</th>
                                <th>Lable Charge</th>

                            </tr>
                        </thead> */}
                                        <tbody>
                                            {Data.otherData && Data.otherData.map((product, index) => (
                                                <tr key={product.id}>

                                                    <td>{index + 1}</td>
                                                    <td><strong>{product.tr_charge}</strong></td>
                                                    <td >{product.lable_charge}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>

                        </Card>
                    </>
                )}
                <Modal
                    size="xl"
                    show={modalState.addproduct}
                    onHide={() => setModalState({ ...modalState, addproduct: false })}
                    aria-labelledby="example-modal-sizes-title-lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>


                        <h3 className="card-title mb-0">Add Product</h3>


                    </Modal.Header>

                    <Modal.Body closeButton>
                        <Addproduct onSave={() => setModalState({ ...modalState, addproduct: false })} />
                    </Modal.Body>
                </Modal>

            </section>

        </>
    );
}

export default OrderProductView;
