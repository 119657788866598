import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
export default function Expenditureview() {
    const token = localStorage.getItem("token");
    const employee_view_id = localStorage.getItem("employee_view_id");
    const [vendorData, setViewdata] = useState([]);
    useEffect(() => {
        VenderView();
    }, []);
    const VenderView = async (id) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Employee-edit/${employee_view_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data[0]);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
        }
    };
    return (
        // <Modal size="lg">
        //     <Modal.Header closeButton>
        //         <Modal.Title><h4>View Details</h4></Modal.Title>
        //     </Modal.Header>
        <Modal.Body>
            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{vendorData.emp_name}</td>
                        </tr>
                        <tr>
                            <th>Designation:</th>
                            <td>{vendorData.designation}</td>
                        </tr>
                        <tr>
                            <th>Role:</th>
                            <td>{vendorData.role}</td>
                        </tr>
                        <tr>
                            <th>Age:</th>
                            <td>{vendorData.age}</td>
                        </tr>
                        <tr>
                            <th>Mobile:</th>
                            <td>{vendorData.mobile_number}</td>
                        </tr>
                        <tr>
                            <th>Salayr:</th>
                            <td>{vendorData.salayr}</td>
                        </tr>
                        <tr>
                            <th>Address:</th>
                            <td>{vendorData.address}</td>
                        </tr>
                       
                        <tr>
                            <th>Email Id:</th>
                            <td>{vendorData.email}</td>
                        </tr>
                        <tr>
                            <th>Joining_date:</th>
                            <td>{vendorData.joining_date}</td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td> <span style={{ color: vendorData.status == 1 ? 'green' : 'red' }}>
                                {vendorData.status == 1 ? 'Active' : 'Inactive'}
                            </span></td>
                        </tr>



                        {/* Render other fields similarly */}
                    </tbody>
                </table>
            </div>
        </Modal.Body>
        // </Modal>
    )
}
