import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
export default function Expenditureview() {
    const token = localStorage.getItem("token");
    const expenditure_id = localStorage.getItem("expenditure_view");
    const [vendorData, setViewdata] = useState([]);
    useEffect(() => {
        VenderView();
    }, []);
    const VenderView = async (id) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}expenditure-edit/${expenditure_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data[0]);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
        }
    };
    return (
        // <Modal size="lg">
        //     <Modal.Header closeButton>
        //         <Modal.Title><h4>View Details</h4></Modal.Title>
        //     </Modal.Header>
        <Modal.Body>
            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Date:</th>
                            <td>{vendorData.date}</td>
                        </tr>
                        <tr>
                            <th>Expenditure Name:</th>
                            <td>{vendorData.expenditure_type_name}</td>
                        </tr>
                        <tr>
                            <th>Expenditure:</th>
                            <td>{vendorData.emp_name || vendorData.customer_name || vendorData.name}</td>
                        </tr>
                        <tr>
                            <th>Expenditure Category:</th>
                            <td>{vendorData.expenditure_category_name}</td>
                        </tr>
                        <tr>
                            <th>Amount:</th>
                            <td>{vendorData.amount}</td>
                        </tr>
                        <tr>
                            <th>Payment By:</th>
                            <td>{vendorData.paid_to}</td>
                        </tr>
                        <tr>
                            <th>Payment Mode:</th>
                            <td>{vendorData.account_mode}</td>
                        </tr>
                        <tr>
                            <th>Name:</th>
                            <td>{vendorData.name}</td>
                        </tr>
                        <tr>
                            <th>Payable Month:</th>
                            <td>{vendorData.payable_month}</td>
                        </tr>
                        <tr>
                            <th>Expenditure Details:</th>
                            <td>{vendorData.exp_details}</td>
                        </tr>
                        <tr>
                            <th>Agents To:</th>
                            <td>{vendorData.customer_type}</td>
                        </tr>

                        <tr>
                            <th>Description:</th>
                            <td>{vendorData.description}</td>
                        </tr>



                        {/* Render other fields similarly */}
                    </tbody>
                </table>
            </div>
        </Modal.Body>
        // </Modal>
    )
}
