import React, { useState, useMemo, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { MdDelete } from 'react-icons/md';
import Vendorproduct from '../Vendorproduct';
import { FiPlusCircle } from 'react-icons/fi';
import { HiOutlineMinusCircle } from 'react-icons/hi';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Paymentdetailspage from './Vendorreporttable';
import Vendorproductpayment from './Vendorreporttable';
import { format } from 'date-fns';


export default function Customerpayment({ onSave }) {
    const token = localStorage.getItem('token');
    const payment_update_id = localStorage.getItem('payment_update');
    const [venderpaymentlist, setvandorpaymentlist] = useState([]);
    const [selectedCustomerId, setselectedCustomerId] = useState(null);
    const initialFields = {
        VenderName: '',
        AccountName: '',
        CustumerNameSearch: '',
        CustumerName: '',
        Amount: '',
        Mode: '',
    };

    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false);
    const [selectoption, setSelectoption] = useState(null);
    const [vendorreprotlist, setvendorreprotlist] = useState([]);
    const [custumerlist, setCustumerlist] = useState([]);
    const [accountlist, setAccountlist] = useState([]);
    const [errors, setErrors] = useState({});


    const handleChange = (value, name) => {
        if (name === 'Mode') {
            setSelectoption(value);
            setFields({ ...fields, Mode: value.value });
        } else if (name === 'AccountName') {
            setFields({ ...fields, AccountName: value });
        } else if (name === 'CustumerName') {
            setFields({ ...fields, CustumerName: value });
            // setselectedCustomerId(value);
        }
        else if (name === 'CustumerNameSearch') {
            setFields({ ...fields, CustumerNameSearch: value });
            setselectedCustomerId(value);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const validateFields = () => {
        const errors = {};
        if (!fields.CustumerName) errors.CustumerName = 'Custumer Name is required';
        if (!fields.AccountName) errors.AccountName = 'Quantity is required';
        if (!fields.Amount) errors.Amount = 'Unit Price is required';
        if (!fields.Mode) errors.Mode = 'Item Name is required';
        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        setErrors({});
        setLoading(true);
        const payload = {
            customer_id: fields.CustumerName,
            account_id: fields.AccountName,
            amount: fields.Amount,
            mode: fields.Mode,
            payment_id: payment_update_id,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Customer-payment-update`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setFields(initialFields);
                setSelectoption(null);
                onSave()
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchCustumer();
    }, [token]);
    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setAccountlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Account:', error);
            }
        };
        fetchAccount();
    }, [token]);
    const options = [
        { value: 'Online', label: 'Online' },
        { value: 'Cash', label: 'Cash' },
    ];
    useEffect(() => {
        PaymentView();
    }, []);
    const PaymentView = async () => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Customer-payment-edit/${payment_update_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const customerData = response.data.data[0];  // Assuming the data is an array with one object

            // Set fields with the fetched data
            setFields({
                Amount: customerData.amount || '',
                AccountName: customerData.account_id || '',
                CustumerName: customerData.customer_id || '',
                mode: customerData.mode || '',
            });
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };
    return (
        <div>
            <Card>
                <Card.Header>
                    <Card.Title>Select Payment</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Amount"
                                        name="Amount"
                                        value={fields.Amount}
                                        onChange={handleInputChange}
                                        style={{ borderColor: errors.Amount ? 'red' : '' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Custumer Name</Form.Label>
                                    <Form.Select
                                        value={fields.CustumerName}
                                        onChange={(e) => handleChange(e.target.value, 'CustumerName')}
                                        style={{ borderColor: errors.CustumerName ? 'red' : '' }}
                                    >
                                        <option value="">Select Custumer Name</option>
                                        {custumerlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.customer_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>


                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Select
                                        value={fields.AccountName}
                                        onChange={(e) => handleChange(e.target.value, 'AccountName')}
                                        style={{ borderColor: errors.AccountName ? 'red' : '' }}
                                    >
                                        <option value="">Select Account Name</option>
                                        {accountlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Select Mode</Form.Label>
                                    <Select
                                        value={selectoption}
                                        onChange={(value) => handleChange(value, 'Mode')}
                                        options={options}
                                        isSearchable={true}
                                        styles={errors.Mode ? { control: (base) => ({ ...base, borderColor: 'red' }) } : {}}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="d-flex justify-content-end">
                                    <Button type="submit" className="btn-added" disabled={loading}>
                                        {loading ? 'Submitting...' : 'Update'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>



                </Card.Body>
            </Card>
            {/* <ToastContainer /> */}
        </div>
    );
}
