import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddVendor from '../commanpage/AddVendor';
import AddCategory from '../commanpage/Categories';

export default function Addstock({ onSave }) {
    const token = localStorage.getItem('token');
    const stock_update_id = localStorage.getItem('stock_update_id');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [venderlist, setVenderlist] = useState([]);
    const [modalState, setModalState] = useState({
        addVendor: false,
        addCategoryt: false,
    });
    const [manufacture] = useState('Manufacture');
    const [rows, setRows] = useState([{
        date: '',
        vendor_id: null,
        category_id: null,
        name: '',
        pro_unit: '',
        hsn_no: '',
        pro_qty: '',
        gst: '',
        price: '',
        manufacture,
        total_amount: ''
    }]);

    const categoryOptions = categories.map((item) => ({ value: item.id, label: item.name }));
    const vendorOptions = venderlist.map((item) => ({ value: item.id, label: item.vendor_name }));


    const handleAddRow = () => {
        setRows([...rows, {
            date: '',
            vendor_id: null,
            category_id: null,
            name: '',
            pro_unit: '',
            hsn_no: '',
            pro_qty: '',
            gst: '',
            price: '',
            total_amount: '',
        }]);
    };

    const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;

        const { pro_qty, gst, price } = updatedRows[index];

        if (pro_qty && price) {
            const quantity = parseFloat(pro_qty) || 0;
            const unitPrice = parseFloat(price) || 0;
            const gstRate = parseFloat(gst) || 0;
            const totalAmount = quantity * unitPrice * (1 + gstRate / 100);
            updatedRows[index].total_amount = totalAmount.toFixed(2);
        } else {
            updatedRows[index].total_amount = '';
        }

        setRows(updatedRows);
    };

    const validateForm = () => {
        // Add your form validation logic here
        return true; // Return true if the form is valid
    };

    const handleSubmit = async (url, formData, successCallback) => {
        setLoading(true);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setRows([{
                    date: '',
                    vendor_id: null,
                    category_id: null,
                    name: '',
                    pro_unit: '',
                    hsn_no: '',
                    pro_qty: '',
                    gst: '',
                    price: '',
                    total_amount: ''
                }]);
                onSave();
                if (successCallback) successCallback(response.data.payload);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitOrder = () => {
        // if (validateForm()) {
        const requestData = {
            products: rows.map(row => ({
                category_id: row.category_id,
                vendor_id: row.vendor_id,
                name: row.name,
                pro_unit: row.pro_unit,
                hsn_no: row.hsn_no,
                pro_qty: row.pro_qty,
                gst: row.gst,
                sale_price: row.price,
                total_amount: row.total_amount,
                manufacture: manufacture,
                id: stock_update_id,
                type: 'add_stock'
            }))
        };

        handleSubmit(
            `${process.env.REACT_APP_API_URL}Product-stock-update`,
            requestData,
            () => {
                // Success callback
                console.log("Order submitted successfully!");
            }
        );
        // } else {
        //     console.log("Validation failed");
        // }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCategories(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories.');
            }
        };
        if (!modalState.addCategoryt) {
            fetchCategories();
        }
    }, [token, modalState.addCategoryt]);
    useEffect(() => {
        const fetchCategories1 = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Product-stoct-edit/${stock_update_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                // setCategories(response.data.data || []);
                const customerData = response.data.data[0];
                console.warn(customerData);
                setRows([{
                    category_id: customerData.category_id || '',
                    vendor_id: customerData.vendor_id || '',
                    price: customerData.sale_man_amount || '',
                    pro_qty: customerData.pro_qty || '',
                    pro_unit: customerData.pro_unit || '',
                    name: customerData.name || '',
                    gst: customerData.gst || '',
                    date: customerData.date || '',
                    hsn_no: customerData.hsn_no || '',
                }]);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories.');
            }
        };
        fetchCategories1();

    }, [token]);

    useEffect(() => {
        const fetchVendor = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setVenderlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching vendors:', error);
                // toast.error('Failed to fetch vendors.');
            }
        };
        if (!modalState.addVendor) {
            fetchVendor();
        }
    }, [token, modalState.addVendor]);

    return (
        <>
            <Card>
                {/* <div className="card"> */}
                <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="card-title mb-0">Stock Product</h3>
                        <div className="d-flex gap-2">
                            <Button onClick={() => setModalState({ ...modalState, addVendor: true })} className="btn btn-added d-flex gap-2">
                                Add Vendor
                            </Button>
                            <Button onClick={() => setModalState({ ...modalState, addCategoryt: true })} className="btn btn-added d-flex gap-2">
                                Add Category
                            </Button>
                        </div>
                    </div>

                </div>

                {/* </div> */}
                <Card.Body>
                    <div className=" d-flex w-100 justify-content-end">
                        {/* <button onClick={handleAddRow} className="btn-addmore">
                            Add More
                        </button> */}
                    </div>
                    {rows.map((row, index) => (
                        <Row key={index} className="mt-3">
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`date_${index}`}>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={row.date}
                                        onChange={(e) => handleRowChange(index, 'date', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`vendor_id_${index}`}>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Select
                                        value={vendorOptions.find((option) => option.value === row.vendor_id)}
                                        options={vendorOptions}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'vendor_id', selected ? selected.value : null)}
                                    />
                                    {/* <Form.Select

                                        value={rows.vendor_id}
                                        onChange={(e) => handleRowChange(e.target.value, 'vendor_id')}
                                    >
                                        <option value="">Select</option>
                                        {venderlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.vendor_name}
                                            </option>
                                        ))}
                                    </Form.Select> */}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`category_id_${index}`}>
                                    <Form.Label>Product Category</Form.Label>
                                    <Select
                                        value={categoryOptions.find((option) => option.value === row.category_id)}
                                        options={categoryOptions}
                                        isSearchable={true}
                                        placeholder="Select"
                                        onChange={(selected) => handleRowChange(index, 'category_id', selected ? selected.value : null)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`name_${index}`}>
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Product Name"
                                        value={row.name}
                                        onChange={(e) => handleRowChange(index, 'name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`pro_unit_${index}`}>
                                    <Form.Label>Unit</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="pro_unit"
                                        placeholder="Unit"
                                        value={row.pro_unit}
                                        onChange={(e) => handleRowChange(index, 'pro_unit', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`hsn_no_${index}`}>
                                    <Form.Label>HSN No</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="hsn_no"
                                        placeholder="HSN No"
                                        value={row.hsn_no}
                                        onChange={(e) => handleRowChange(index, 'hsn_no', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`pro_qty_${index}`}>
                                    <Form.Label>QTY</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="pro_qty"
                                        placeholder="Qty"
                                        value={row.pro_qty}
                                        onChange={(e) => handleRowChange(index, 'pro_qty', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`gst_${index}`}>
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="gst"
                                        placeholder="GST"
                                        value={row.gst}
                                        onChange={(e) => handleRowChange(index, 'gst', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`price_${index}`}>
                                    <Form.Label>Rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        placeholder="Enter Price"
                                        value={row.price}
                                        onChange={(e) => handleRowChange(index, 'price', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4} lg={2}>
                                <Form.Group className="mb-3" controlId={`total_amount_${index}`}>
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="total_amount"
                                        placeholder="Total Amount"
                                        value={row.total_amount}
                                        disabled
                                        onChange={(e) => handleRowChange(index, 'total_amount', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={1} xs={12} lg={1} className="d-flex align-items-center">
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteRow(index)}
                                >
                                    <RiDeleteBin6Line />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <div className=" d-flex w-100 justify-content-end">
                        <Button className="btn takeorder" disabled={loading} onClick={handleSubmitOrder} >
                            {loading ? 'Saving...' : 'Update'}
                        </Button>
                    </div>
                </Card.Body>

            </Card>
            <Modal
                size="lg"
                show={modalState.addVendor}
                onHide={() => setModalState({ ...modalState, addVendor: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Body>
                    <AddVendor onSave={() => setModalState({ ...modalState, addVendor: false })} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.addCategoryt}
                onHide={() => setModalState({ ...modalState, addCategoryt: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Body>
                    <AddCategory onSave={() => setModalState({ ...modalState, addCategoryt: false })} />
                </Modal.Body>
            </Modal>
        </>
    );
}
