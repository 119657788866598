import React, { useState, useMemo, useEffect } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
// import Paymentdetailspage from './Vendorreporttable';
import Vendorproductpayment from './Vendorreporttable';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';



export default function Addproductmatrial() {
    const token = localStorage.getItem('token');
    const [venderpaymentlist, setvandorpaymentlist] = useState([]);
    const [selectedVendorId, setSelectedVendorId] = useState(null);

    const initialFields = {
        VenderName: '',
        AccountName: '',
        CustumerName: '',
        Amount: '',
        Mode: '',
    };

    const [fields, setFields] = useState(initialFields);
    const [loading, setLoading] = useState(false);
    const [selectoption, setSelectoption] = useState(null);
    const [venderlist, setVenderlist] = useState([]);
    const [vendorreprotlist, setvendorreprotlist] = useState([]);
    const [custumerlist, setCustumerlist] = useState([]);
    const [accountlist, setAccountlist] = useState([]);
    const [errors, setErrors] = useState({});
    const [counters, setCounters] = useState([]);
    const [vendorData, setVendorData] = useState([]);

    const handleChange = (value, name) => {
        if (name === 'Mode') {
            setSelectoption(value);
            setFields({ ...fields, Mode: value.value });
        } else if (name === 'VenderName') {
            setFields({ ...fields, VenderName: value });
            setSelectedVendorId(value);
        } else if (name === 'AccountName') {
            setFields({ ...fields, AccountName: value });
        } else if (name === 'CustumerName') {
            setFields({ ...fields, CustumerName: value });
            // VenderView(value);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const validateFields = () => {
        const errors = {};
        if (!fields.VenderName) errors.VenderName = 'Vendor Name is required';
        if (!fields.CustumerName) errors.CustumerName = 'Custumer Name is required';
        if (!fields.AccountName) errors.AccountName = 'Quantity is required';
        if (!fields.Amount) errors.Amount = 'Unit Price is required';
        if (!fields.Mode) errors.Mode = 'Item Name is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }
        setErrors({});
        setLoading(true);

        const payload = {
            vendor_id: fields.VenderName,
            create_order_id: fields.CustumerName,
            account_name: fields.AccountName,
            amount: fields.Amount,
            mode: fields.Mode,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Vendor-payment-store`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setFields(initialFields);
                setSelectoption(null);
                RowMatrialslist();
                fetchVendorReport();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        VenderView()
    }, []);
    const VenderView = async (vendorId) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}Vender-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setVendorData(response.data.data || []); // Set the vendor data in state
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };
    useEffect(() => {
        const fetchVendor = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Vender-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setVenderlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchVendor();
    }, [token]);

    useEffect(() => {
        const fetchCustumer = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setCustumerlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Vendor:', error);
            }
        };

        fetchCustumer();
    }, [token]);

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setAccountlist(response.data.data || []);
            } catch (error) {
                console.error('Error fetching Account:', error);
            }
        };

        fetchAccount();
    }, [token]);

    useEffect(() => {
        fetchVendorReport();
    }, [selectedVendorId]);

    // if (selectedVendorId) {
    const fetchVendorReport = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Vendor-report-list/${selectedVendorId}`,
                { vendor_id: selectedVendorId }, // Payload for the request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
            setvendorreprotlist(response.data.data || []);
        } catch (error) {
            console.error('Error fetching Vendor report:', error);
        }
    };


    // }

    const options = [
        { value: 'Online', label: 'Online' },
        { value: 'Cash', label: 'Cash' },
    ];

    const title = "View Payment Details";
    const title1 = "Vendor Report";

    const RowMatrialslist = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-payment-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setvandorpaymentlist(response.data.data.data || []);
            // console.warn(response.data.data.data)
        } catch (error) {
            console.error('Error fetching Vendor:', error);
        }
    };

    useEffect(() => {
        RowMatrialslist();
    }, [token]);

    const transformedVendorPaymentList = useMemo(() => {
        return venderpaymentlist.map(item => ({
            ...item,
            totaldue: item.total_payments - item.totalSum,
        }));
    }, [venderpaymentlist]);

    // const Vendorproduct = useMemo(
    //     () => [
    //         {
    //             Header: 'Sr No',
    //             accessor: (row, i) => i + 1,
    //         },
    //         {
    //             Header: 'Customer Name',
    //             accessor: 'customer_name',
    //         },
    //         {
    //             Header: 'Vendor Name',
    //             accessor: 'vendor_name',
    //         },
    //         {
    //             Header: 'Total Receive',
    //             accessor: 'totalSum',
    //         },
    //         {
    //             Header: 'Total Paid',
    //             accessor: 'total_payments',
    //         },
    //         {
    //             Header: 'Total Due',
    //             accessor: 'totaldue',
    //             Cell: ({ value }) => {
    //                 const formattedValue = value >= 0 ? `+${value}` : value;
    //                 return <span>{formattedValue}</span>;
    //             },
    //         },
    //     ],
    //     []
    // );
    const Vendorproduct1 = useMemo(
        () => [
            {
                Header: 'Sr No',
                accessor: (row, i) => i + 1,
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: ({ value }) => {
                    return format(new Date(value), 'dd-MM-yyyy');
                },
            },
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
            },
            {
                Header: 'Vendor Name',
                accessor: 'vendor_id',
            },

            {
                Header: 'Receive',
                accessor: 'amount',
            },
            {
                Header: 'Account',
                accessor: 'account_name',
            },
            {
                Header: 'Payment Mode',
                accessor: 'mode',
            },


        ],
        []
    );

    return (
        <div>
            <Card>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <Card.Title className='mb-0'>Select Payment</Card.Title>
                    {/* <Col md={6} className="d-flex justify-content-end"> */}
                        <Link to="/vendermanagement">
                            <Button variant="dark">Back</Button>
                        </Link>
                    {/* </Col> */}
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Amount"
                                        name="Amount"
                                        value={fields.Amount}
                                        onChange={handleInputChange}
                                        style={{ borderColor: errors.Amount ? 'red' : '' }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Custumer Name</Form.Label>
                                    <Form.Select
                                        value={fields.CustumerName}
                                        onChange={(e) => handleChange(e.target.value, 'CustumerName')}
                                        style={{ borderColor: errors.CustumerName ? 'red' : '' }}
                                    >
                                        <option value="">Select Custumer Name</option>
                                        {custumerlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.customer_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Select
                                        value={fields.VenderName}
                                        onChange={(e) => handleChange(e.target.value, 'VenderName')}
                                        style={{ borderColor: errors.VenderName ? 'red' : '' }}
                                    >
                                        <option value="">Select Vendor Name</option>
                                        {vendorData.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.vendor_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Select
                                        value={fields.AccountName}
                                        onChange={(e) => handleChange(e.target.value, 'AccountName')}
                                        style={{ borderColor: errors.AccountName ? 'red' : '' }}
                                    >
                                        <option value="">Select Account Name</option>
                                        {accountlist.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group>
                                    <Form.Label>Select Mode</Form.Label>
                                    <Select
                                        value={selectoption}
                                        onChange={(value) => handleChange(value, 'Mode')}
                                        options={options}
                                        isSearchable={true}
                                        styles={errors.Mode ? { control: (base) => ({ ...base, borderColor: 'red' }) } : {}}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="d-flex justify-content-end">
                                    <Button type="submit" className="btn-added" disabled={loading}>
                                        {loading ? 'Submitting...' : 'Pay'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                    {/* <Paymentdetailspage title={title} columns={Vendorproduct} data={transformedVendorPaymentList} /> */}
                    {/* <hr></hr> */}
                    {/* <div className='row justify-content-center'>
                        <Col md={3} sm={12}>
                            <Form.Group>
                                <Form.Select
                                    value={fields.VenderName}
                                    onChange={(e) => handleChange(e.target.value, 'VenderName')}
                                    style={{ borderColor: errors.VenderName ? 'red' : '' }}
                                >
                                    <option value="">Select Vendor Name</option>
                                    {vendorData.map((vendor) => (
                                        <option key={vendor.id} value={vendor.id}>
                                            {vendor.vendor_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </div> */}
                    <Vendorproductpayment title={title1} columns={Vendorproduct1} data={vendorreprotlist} />
                    {vendorreprotlist.length > 0 ? (
                        null
                    ) : (
                        <p style={{ color: "red", textAlign: "center" }}>Please select a vendor to view payment details.</p>
                    )}
                </Card.Body>
            </Card>
            <ToastContainer />
        </div>
    );
}
