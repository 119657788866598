import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';
// Import the Bootstrap Modal component
import { Modal as BootstrapModal } from 'react-bootstrap'; // Ensure react-bootstrap is installed

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PieChart = () => {
  const [modalState, setModalState] = useState({ Design: false, Retail: false, Site: false });
  const [modalContent, setModalContent] = useState(null); // State for modal content

  const handleSliceClick = (e) => {
    const label = e.dataPoint.label;
    if (['Product Quantity', 'Metal Quantity'].includes(label)) {
      setModalContent({
        label: label,
        value: e.dataPoint.y
      });
      setModalState(prevState => ({ ...prevState, [label]: true }));
    }
  };
  const [chartOptions, setChartOptions] = useState({
    exportEnabled: false,
    animationEnabled: true,
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: "<b>{label}</b>: {y}",
      showInLegend: true,
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}",
      indexLabelPlacement: "inside",
      dataPoints: [], // Initialize with empty dataPoints
      click: handleSliceClick
    }]
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Dashbord-total-stock-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });

      console.log(response.data); // Log the entire response
      const { data } = response.data; // Destructure the data from the response

      console.log(data); // Log the data to check its structure

      // Assuming `data` now contains total_pro_qty and total_met_qty
      const dataPoints = [
        {
          y: data.total_pro_qty,
          label: 'Row Meterial',
          color: '#FF0000',
          indexLabelFontColor: "#FFFFFF"
        },
        {
          y: data.total_met_qty,
          label: 'Stock Meterial',
          color: '#ba1654',
          indexLabelFontColor: "#FFFFFF"
        }
      ];
      setChartOptions(prevOptions => ({
        ...prevOptions,
        data: [{
          ...prevOptions.data[0],
          dataPoints
        }]
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const closeModal = () => {
    setModalState({ 'Stock Meterial Qty': false, 'Row Meterial Qty': false });
    setModalContent(null);
  };

  return (
    <div style={{ height: '100%', width: '100%' }} className='position-relative'>
      <CanvasJSChart options={chartOptions} />
      {modalContent && (
        <BootstrapModal
          show={modalState[modalContent.label]}
          onHide={closeModal}
          size='lg'
          aria-labelledby="modal-title"
          backdrop="static"
          keyboard={false}
        >
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title id="modal-title">{modalContent.label} Details</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            {/* Display content based on the label */}
            <p>{modalContent.label} Value: {modalContent.value}</p>
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
    </div>
  );
};

export default PieChart;
