import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Container, Card } from 'react-bootstrap';
import DataTable from './Stocktable';
import axios from 'axios';

const StockManagement = () => {
    const token = localStorage.getItem('token');
    const ven_ladger_id = localStorage.getItem('ven_ladger_id');
    const [loading, setLoading] = useState(false);
    const [data, setVenderlist] = useState([]);

    useEffect(() => {
        fetchVendor();

    }, [token]);

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Vendor-ladger-list/${ven_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setVenderlist(response.data.data || []);
        } catch (error) {
            console.error('Error fetching vendors:', error);
            // toast.error('Failed to fetch vendors.');
        }
    };

    // Define columns
    const columns = useMemo(
        () => [
            {
                Header: 'Sr.No',
                accessor: 'Srno',
                Cell: (row) => {
                    return row.row.index + 1;
                },
            },
            {
                Header: 'Date',
                accessor: 'order_date',
            },
            //   {
            //     Header: 'Product Name',
            //     accessor: 'productname',
            //     accessor: 'gst',
            //   },
            {
                Header: 'Product Name & GST',
                accessor: 'productname', 
                Cell: ({ row }) => {
                    return `${row.original.productname}@: ${row.original.gst}`;
                },
            },
            {
                Header: 'Type',
                // accessor: 'productname', 
                Cell: ({ row }) => {
                    return `${'Sale'}`;
                },
            },
            {
                Header: 'Invoice',
                accessor: 'invoice_number_manual',
            },
            {
                Header: 'Debit',
                accessor: 'firstprice',
            },
        ],
        []
    );
    return (
        <>

            <div>
                <DataTable columns={columns} data={data} />
                {/* Example modal component */}
                <Modal id="add-units" show={false} onHide={() => { }} backdrop="static" 
          keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Your modal content here */}
                        {/* This is just a placeholder, replace it with your actual modal content */}
                        <p>Modal Content</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { }}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => { }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>

    );
};

export default StockManagement;
