import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Card, Table, Button } from 'react-bootstrap';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

export default function CustumerProductview() {
    const VendorDetails_id = localStorage.getItem("Vendor_view_id");
    const [vendorData, setVendorData] = useState([]);
    console.warn(vendorData);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        fetchVendorData();
    }, []);

    const fetchVendorData = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}Vendor-receive-view/${VendorDetails_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            console.log("API Response:", response); // Log the response for debugging
            const fetchedData = response.data || {};
            // console.warn(fetchedData);
            if (fetchedData.status == 1) {
                setVendorData(fetchedData.data || []);
            } else {
                console.error("API Error:", fetchedData.message); // Log any API errors
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    const goBack = () => {
        navigate('/orderview');
    };

    return (
        <div className='Vendorview'>
            {/* <div className="page-header">
               
                <div className="button_add_row"></div>
            </div> */}

            {vendorData.length === 0 ? (
                <Card>
                    <Card.Body>
                        <p>No data available</p>
                    </Card.Body>
                </Card>
            ) : (
                vendorData.map((product, index) => (
                    <Card key={index} className="mb-4 border">
                        <Card.Body>
                            <h6>Vendor Details</h6>
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="thead-primary">
                                        <th>Sr.No</th>
                                        {/* <th>Order Id</th> */}
                                        <th>Date</th>
                                        {/* <th>Vendor Name</th> */}
                                        <th>Qty</th>
                                        <th>Unit Price</th>
                                        <th>Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{index + 1}</td>
                                        {/* <td>{product.order_id}</td> */}
                                        <td>{product.date}</td>
                                        {/* <td>{product.vendor_id}</td> */}
                                        <td>{product.qty}</td>
                                        <td>{product.unit_price}</td>
                                        <td>{product.total_price}</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                ))
            )}
        </div>
    );
}
