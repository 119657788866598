import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Expenditure_Type from '../expenditure/Expenditure_Type';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

export default function UpdateExpenditure({ onSave }) {
    const token = localStorage.getItem('token');
    const expenditure_update_id = localStorage.getItem('expenditure_update');
    const [custumerlist, setCustumerlist] = useState([]);
    const [custumernamelist, setCustumernameList] = useState([]);
    // console.warn(custumernamelist);
    const [employelist, setEmployelist] = useState([]);
    const [category_list, setExpenditureCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountlist, setAccountList] = useState([]);
    const [filteredCustomerList, setFilteredCustomerList] = useState([]);
    const [modalState, setModalState] = useState({ addExpenditureType: false });

    const initialFields = {
        expenditure_id: '',
        expenditure_category_id: '',
        employee_id: '',
        customer_id: '',
        customer_type: '',
        date: '',
        received_by: '',
        exp_details: '',
        payable_month: '',
        amount: '',
        account_mode: '',
        account_id: '',
        name: '',
        description: '',
    };
    const [fields, setFields] = useState(initialFields);

    const handleChange = (value, name) => {
        setFields(prevFields => ({ ...prevFields, [name]: value }));
    };

    // const handleSelectChange = (name, selectedOption) => {
    //     if (name === 'customer_type') {
    //         const filteredList = custumernamelist.filter(customer => customer.customer_type === selectedOption.value);
    //         setFilteredCustomerList(filteredList);
    //     }

    //     setFields(prevFields => ({
    //         ...prevFields,
    //         [name]: selectedOption ? selectedOption.value : null
    //     }));
    // };

    const handleSelectChange = (name, selectedOption) => {
        if (name === 'customer_type') {
            const filteredList = custumernamelist.filter(customer => customer.customer_type === selectedOption.value);
            setFilteredCustomerList(filteredList);
        }

        setFields(prevFields => ({
            ...prevFields,
            [name]: selectedOption ? selectedOption.value : null // Store only the ID
        }));
    };



    const handleSubmitOrder = async () => {
        if (!fields.expenditure_id) {
            toast.error('Please Select an Expenditure Type');
            return;
        }
        if (!fields.date) {
            toast.error('Please Select an Date');
            return;
        }
        setLoading(true);
        const requestData = {
            ...fields,
            expenditure_id: expenditure_update_id
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}expenditure-update`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                }
            );
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setFields(initialFields);
                onSave(); // Trigger the onSave function
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            // toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [custumerResponse, employeeResponse, accountResponse, custumernameResponse, ExpenditureCategory] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}expenditure-type-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Employee-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}Custumer-name-list`, {
                        headers: { 'x-access-token': token }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}expenditure-category-list`, {
                        headers: { 'x-access-token': token }
                    }),
                ]);

                setCustumerlist(custumerResponse.data.data || []);
                setEmployelist(employeeResponse.data.data || []);
                setAccountList(accountResponse.data.data || []);
                setCustumernameList(custumernameResponse.data.data || []);
                setExpenditureCategoryList(ExpenditureCategory.data.data || []);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (!modalState.addExpenditureType) {
            fetchData();
        }
    }, [token, modalState.addExpenditureType]);
    useEffect(() => {
        VenderView();
    }, []);
    const VenderView = async () => {
        try {
            let url = `${process.env.REACT_APP_API_URL}expenditure-edit/${expenditure_update_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const customerData = response.data.data[0];  // Assuming the data is an array with one object

            // Set fields with the fetched data
            setFields({
                expenditure_id: customerData.expenditure_id || '',
                employee_id: customerData.employee_id || '',
                customer_id: customerData.customer_id || '',
                expenditure_category_id: customerData.expenditure_category_id || '',
                customer_id: customerData.customer_id || '',
                customer_type: customerData.customer_type || '',
                date: customerData.date || '',
                received_by: customerData.received_by || '',
                exp_details: customerData.exp_details || '',
                payable_month: customerData.payable_month || '',
                amount: customerData.amount || '',
                account_mode: customerData.account_mode || '',
                account_id: customerData.account_id || '',
                name: customerData.name || '',
                description: customerData.description || '',
            });
            if (customerData.customer_type) {
                const filteredList = custumernamelist.filter(customer => customer.customer_type === customerData.customer_type);
                setFilteredCustomerList(filteredList);
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    const accountModeOptions = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Online', label: 'Online' },
    ];

    const customerTypeOptions = [
        { value: 'Retail', label: 'Retail' },
        { value: 'Site', label: 'Site' },
        { value: 'Design', label: 'Design' },
    ];
    const accountlist_data = accountlist ? accountlist.map(employee => ({
        value: employee.id,
        label: employee.account_name
    })) : [];
    const emp_data = employelist ? employelist.map(employee => ({
        value: employee.id,
        label: employee.emp_name
    })) : [];

    const cus_data = filteredCustomerList ? filteredCustomerList.map(customer => ({
        value: customer.id,
        label: customer.customer_name
    })) : [];
    const payable_month = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' }
    ];


    return (
        <>
            <section className="takeorder_section">
                <div className="page_design">

                    <Card>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} sm={6}>
                                        <Form.Group >
                                            <div className="d-flex justify-content-between">
                                                <Form.Label >Expenditure Type</Form.Label>
                                                <Button
                                                    className='btn-info btn-sm ml-1'
                                                    onClick={() => setModalState({ ...modalState, addExpenditureType: true })}
                                                    variant="primary"
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <Form.Select

                                                value={fields.expenditure_id}
                                                onChange={(e) => handleChange(e.target.value, 'expenditure_id')}
                                            >
                                                <option value="">Select</option>
                                                {custumerlist.map((vendor) => (
                                                    <option key={vendor.id} value={vendor.id}>
                                                        {vendor.expenditure_type_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {fields.expenditure_id === '1' && (
                                        <>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group >
                                                    <Form.Label >Employee Name</Form.Label>
                                                    <Form.Select
                                                        value={fields.employee_id}
                                                        onChange={(e) => handleChange(e.target.value, 'employee_id')}
                                                    >
                                                        <option value="">Select</option>
                                                        {employelist.map((vendor) => (
                                                            <option key={vendor.id} value={vendor.id}>
                                                                {vendor.emp_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group >
                                            </Col>

                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="received_by">
                                                    <Form.Label>Received By</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="received_by"
                                                        placeholder="Received By"
                                                        value={fields.received_by}
                                                        onChange={(e) => handleChange(e.target.value, 'received_by')}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="employee_id">
                                                    <Form.Label>Payable Month</Form.Label>
                                                    <Select
                                                        value={payable_month.find(option => option.value === fields.payable_month)}
                                                        onChange={(selected) => handleSelectChange('payable_month', selected)}
                                                        options={payable_month}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                    {fields.expenditure_id === '2' && (
                                        <>
                                        <Col md={6} sm={6}>
                                                <Form.Group >
                                                    <div className="d-flex justify-content-between">
                                                        <Form.Label >Expenditure Category</Form.Label>
                                                        <Button
                                                            className='btn-info btn-sm ml-1'
                                                            onClick={() => setModalState({ ...modalState, category_expenditure: true })}
                                                            variant="primary"
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                    <Form.Select

                                                        value={fields.expenditure_category_id}
                                                        onChange={(e) => handleChange(e.target.value, 'expenditure_category_id')}
                                                    >
                                                        <option value="">Select</option>
                                                        {category_list.map((vendor) => (
                                                            <option key={vendor.id} value={vendor.id}>
                                                                {vendor.expenditure_category_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="exp_details">
                                                    <Form.Label>Expenditure Details</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="exp_details"
                                                        placeholder="Expenditure Details"
                                                        value={fields.exp_details}
                                                        onChange={(e) => handleChange(e.target.value, 'exp_details')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group controlId="customer_type">
                                                    <Form.Label>Agents To</Form.Label>
                                                    <Select
                                                        value={customerTypeOptions.find(option => option.value === fields.customer_type)}
                                                        onChange={(selected) => handleSelectChange('customer_type', selected)}
                                                        options={customerTypeOptions}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} lg={6} xs={3}>
                                                <Form.Group>
                                                    <Form.Label>Customer Name</Form.Label>
                                                    <Form.Select
                                                        value={fields.customer_id || ''}
                                                        onChange={(e) => handleSelectChange('customer_id', e.target.value)}
                                                    >
                                                        <option value="">Select Customer</option>
                                                        {filteredCustomerList.map((customer) => (
                                                            <option key={customer.id} value={customer.id}>
                                                                {customer.customer_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="amount">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                placeholder='Amount'
                                                value={fields.amount}
                                                onChange={(e) => handleChange(e.target.value, 'amount')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {(fields.expenditure_id !== '1' && fields.expenditure_id !== '2') && (
                                        <Col md={6} lg={6} xs={3}>
                                            <Form.Group controlId="name">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={fields.name}
                                                    onChange={(e) => handleChange(e.target.value, 'name')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="date">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                value={fields.date}
                                                onChange={(e) => handleChange(e.target.value, 'date')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="account_mode">
                                            <Form.Label>Expenditure Mode</Form.Label>
                                            <Select
                                                value={accountModeOptions.find(option => option.value === fields.account_mode)}
                                                onChange={(selected) => handleSelectChange('account_mode', selected)}
                                                options={accountModeOptions}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6} xs={3}>
                                        <Form.Group controlId="account_id">
                                            <Form.Label>Select Account</Form.Label>
                                            <Select
                                                value={accountlist_data && accountlist_data.find(option => option.value === fields.account_id)}
                                                onChange={(selected) => handleSelectChange('account_id', selected)}
                                                options={accountlist_data}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} xs={12} lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                placeholder="Description"
                                                value={fields.description}
                                                onChange={(e) => handleChange(e.target.value, 'description')}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="d-flex justify-content-end align-items-center w-95 mb-1">
                                <div className="d-flex gap-1">
                                    <Button
                                        className="btn takeorder"
                                        disabled={loading}
                                        onClick={handleSubmitOrder}
                                    >
                                        {loading ? 'Saving...' : 'Create'}
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>
                </div>
            </section >
            <Modal
                show={modalState.addExpenditureType}
                onHide={() => setModalState({ ...modalState, addExpenditureType: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h3 className="card-title mb-0">Add Expenditure Type</h3>
                </Modal.Header>
                <Modal.Body>
                    <Expenditure_Type onSave={() => setModalState({ ...modalState, addExpenditureType: false })} />
                </Modal.Body>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
}
