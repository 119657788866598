import React, { useMemo, useState, useEffect } from 'react'
import Ordertable from './Ordertable'
import { MdDelete } from "react-icons/md";

import Orderid from './Orderid'
import { Row, Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { MdAutoGraph } from 'react-icons/md';
import { MdCurrencyRupee } from "react-icons/md";
import { CgFileDocument, CgCalendarDue } from "react-icons/cg";
import { GiReceiveMoney } from "react-icons/gi";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaPlus, FaEdit } from "react-icons/fa";
import Swal from 'sweetalert2';
import { GrDocumentPdf } from "react-icons/gr";
import OrderProductPdfDownload from '../OrderProductPdfDownload'
import ReceiveView from './ReceiveView'
import DueView from './DueView';

export default function Orderdetails() {
  const token = localStorage.getItem('token');
  const [orderlist, setOrderList] = useState([]);
  const [productlist, setProductList] = useState([]);
  const navigate = useNavigate();
  const [modalState, setModalState] = useState({ orderconfirmation: false, receiveview: false, dueview: false });
  const [selectedStatus, setSelectedStatus] = useState('');
  // console.warn(orderlist);

  useEffect(() => {
    fetchOrderlist();
  }, []);

  const fetchOrderlist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setOrderList(response.data.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const OrderDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}Order-delete/${id}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchOrderlist();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      // setLoadinglist(false);
    }
  };

  const totalSalePrice = useMemo(() => {
    if (!orderlist || orderlist.length === 0) {
      return 0;
    }
    return orderlist.reduce((total, product) => total + parseFloat(product.amount || 0), 0);
  }, [orderlist]);

  const roundedTotalSalePrice = Math.round(totalSalePrice);

  const totaltotal_price = useMemo(() => {
    if (!orderlist || orderlist.length === 0) {
      return 0;
    }
    return orderlist.reduce((total, product) => {
      const totalPrice = parseFloat(product.total_amount) || 0;
      // const totalLableCharge = parseFloat(product.total_lable_charge) || 0;
      return total + totalPrice;
    }, 0);
  }, [orderlist]);

  const roundedTotalPrice = Math.round(totaltotal_price);

  // Compute the due amount using raw numbers
  const due_amount = roundedTotalPrice - roundedTotalSalePrice;

  // Format the rounded total prices for display
  const formattedTotalPrice = roundedTotalPrice.toLocaleString('en-IN');
  const formattedTotalSalePrice = roundedTotalSalePrice.toLocaleString('en-IN');
  const formattedDueAmount = due_amount.toLocaleString('en-IN');

  const handleView = (order) => {
    localStorage.setItem('orderproduct_id', order);
    navigate('/Order-Product-View');
  };

  const handleViewpdf = (order, orderType) => {
    if (orderType === 'invoice') {
      navigate('/Order-Invoice-Pdf');
    } else if (orderType === 'product') {
      navigate('/Order-Product-Pdf');
    } else if (orderType === 'bill_supply') {
      navigate('/Order-BillSupply-Pdf');
    } else if (orderType === 'design') {
      navigate('/Order-Design-Pdf');
    }
    
    localStorage.setItem('orderproduct_id_pdf', order);
    setModalState({ ...modalState, orderconfirmation: true })
  };

  const handleEdit = (order, orderid) => {
    localStorage.setItem('order_Update_id', order);
    localStorage.setItem('up_orderid', orderid);
    navigate('/Take-order-update');
  };

  const headertable = useMemo(
    () => [
      {
        Header: 'Sr.No.',
        accessor: (row, i) => i + 1,
      },
      {
        Header: 'ID No',
        accessor: (row) => {
          const boldStyle = { fontWeight: 'bold', color: '#ba1654' };

          if (row.order_type === 'invoice') {
            return (
              <span  className='d-flex'>
                <span style={boldStyle}>INV:</span> {row.invoice_number_manual}
              </span>
            );
          } else if (row.order_type === 'product') {
            return (
              <span  className='d-flex'>
                <span style={boldStyle}>ORD:</span>{row.id}
              </span>
            );
          } else if (row.order_type === 'design') {
            return (
              <span  className='d-flex'>
                <span style={boldStyle}>DES:</span>{row.id}
              </span>
            );
          }
          else if (row.order_type === 'bill_supply') {
            return (
              <span  className='d-flex'>
                <span style={boldStyle}>SUP:</span>{row.id}
              </span>
            );
           }
        },
      },



      // {
      //   Header: 'Invoice No',
      //   accessor: 'invoice_number',
      // },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },

      {
        Header: 'Date',
        accessor: (row) => {
          const date = new Date(row.order_date);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
      {
        Header: 'Delivery Date',
        // accessor: 'delivery_date',
        accessor: (row) => {
          const date = new Date(row.delivery_date);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
      {
        Header: 'Total',
        accessor: 'total_amount',
        Cell: ({ value }) => {
          const numericValue = Number(value);
          const roundedValue = Math.round(numericValue);
          return roundedValue.toLocaleString('en-IN');
        },
      },
      {
        Header: 'Receive',
        accessor: 'amount',
        Cell: ({ value }) => {
          const numericValue = Number(value);
          const roundedValue = Math.round(numericValue);
          return roundedValue.toLocaleString('en-IN');
        },
      },

      {
        Header: 'Due Am',
        accessor: 'due_amt',
        Cell: ({ value }) => {
          const numericValue = Number(value);
          const roundedValue = Math.round(numericValue);
          return roundedValue.toLocaleString('en-IN')
        }
      },

      {
        Header: 'Customer Type',
        accessor: 'customer_type',
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className='d-flex '>
            <Button style={{ "--i": "red" }} onClick={() => handleViewpdf(row.original.id, row.original.order_type)} className="ms-2"><GrDocumentPdf /></Button>
            <Button variant="secondary" onClick={() => handleView(row.original.id)} className="ms-2"><FaEye /></Button>
            <Button variant="info" className=" ms-2" onClick={() => handleEdit(row.original.id, row.original.order_id)} ><FaEdit /></Button>
            <Button variant="danger" className="ms-2" onClick={() => OrderDelete(row.original.id)} ><MdDelete /></Button>
          </div>
        ),
      },
    ],
    []
  );
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
  };

  // Filter orders based on the selected status
  const filteredOrderList = selectedStatus ? orderlist.filter(item => item.customer_type === selectedStatus) : orderlist;
  return (
    <>
      {/* <main className="mt-md-3 mt-sm-1">
        <Row>
          <Col md={4} xs={12} lg={4}>
            <Card>
              <Card.Body>
                <div className="box box-body">
                  <div className="d-flex justify-content-between">
                    <div className="icon_total"> <CgFileDocument /></div>
                    <div>
                      <h6>
                        <span className="text-uppercase fs-18 fw-bold">Total</span>
                      </h6>
                      <p className="fs-26">
                        <MdCurrencyRupee />
                        <strong>{formattedTotalPrice}</strong>
                      </p>
                    </div>
                  </div>

                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} xs={12} lg={4}>
            <Link to="/ReceiveView">
              <Card>
                <Card.Body>
                  <div className="box box-body">
                    <div className="d-flex justify-content-between">
                      <div className="icon_total"> <GiReceiveMoney /></div>
                      <div>
                        <h6>
                          <span className="text-uppercase fs-18 fw-bold">Receive</span>
                        </h6>
                        <p className="fs-26">
                          <MdCurrencyRupee />
                          <strong>{formattedTotalSalePrice}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} xs={12} lg={4}>
            <Link to="/DueView">
              <Card>
                <Card.Body>
                  <div className="box box-body">
                    <div className="d-flex justify-content-between">
                      <div className="icon_total"> <CgCalendarDue /></div>
                      <div>
                        <h6>
                          <span className="text-uppercase fs-18 fw-bold">Due</span>
                        </h6>
                        <p className="fs-26">
                          <MdCurrencyRupee />
                          <strong>{formattedDueAmount}</strong>


                        </p>
                      </div>
                    </div>

                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>

        </Row>
      </main> */}

      <div className='justify-content-center processing'>
        <div className='text-center'>
          <Row>
            <Col md={12} xs={12} lg={12}>
              <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '2px solid brown' }}>
                <option value=''>Customer Type</option>
                <option value="Retail">Retail</option>
                <option value="Site">Site</option>
                <option value="Design">Design</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
      <Ordertable data={filteredOrderList} columns={headertable} />
      <hr />
      {/* <Ordertable columns={OrderId} data={productlist} /> */}

      {/* </section > */}
      {/* <Modal
        size="lg"
        show={modalState.orderconfirmation}
        onHide={() => setModalState({ ...modalState, orderconfirmation: false })}
        backdrop="static" 
        keyboard={false} 
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Order Pdf</h3>
        </Modal.Header>

        <Modal.Body closeButton>
          <OrderProductPdfDownload onSave={() => setModalState({ ...modalState, orderconfirmation: false })} />
        </Modal.Body>
      </Modal> */}

      <Modal
        show={modalState.receiveview}
        size='lg'
        onHide={() => setModalState({ ...modalState, receiveview: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        data-backdrop="static" data-keyboard="false"
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Customer Receive Amount</h3>
        </Modal.Header>
        <Modal.Body>
          <ReceiveView onSave={() => setModalState({ ...modalState, receiveview: false })} />
        </Modal.Body>
      </Modal>
      <Modal
        show={modalState.dueview}
        size='lg'
        onHide={() => setModalState({ ...modalState, dueview: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" 
          keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="card-title mb-0">Customer Due Amount</h3>
        </Modal.Header>
        <Modal.Body>
          <DueView onSave={() => setModalState({ ...modalState, receiveview: false })} />
        </Modal.Body>
      </Modal>
    </>
  );
}
