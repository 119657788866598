import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;

        if (key === 'MobileNumber') {
            value = value.replace(/\D/g, ''); // Remove non-numeric characters
            value = value.slice(0, 10); // Limit to 10 characters
        }

        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const initialFields = {
        SelectEmployName: '',
        AccountType: '',
        AccountName: '',
        AccountNumber: '',
        BankName: '',
        MobileNumber: '',
        Ifcecode: '',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    const fetchEmployeeList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Employee-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setEmployeeList(response.data.data);
        } catch (error) {
            console.error('Error fetching employee list:', error);
        }
    };
    useEffect(() => {
        EmployeeView();
    }, []);

    const EmployeeView = async () => {
        const vendorUpdate_id = localStorage.getItem('employee_id');
        try {
            let url = `${process.env.REACT_APP_API_URL}Account-edit/${vendorUpdate_id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            const data = response.data.data[0]; // Assuming you are expecting only one vendor data object

            setFields({
                SelectEmployName: data.emp_id || '',
                AccountType: data.account_type || '',
                AccountName: data.account_name || '',
                AccountNumber: data.account_number || '',
                BankName: data.bank_name || '',
                MobileNumber: data.mobile_number || '',
                Ifcecode: data.ifce_code || '',

            });
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const validateFields = (fields, setErrors) => {
        const newErrors = {};
        let isValid = true;

        if (!fields.SelectEmployName) {
            newErrors.SelectEmployName = true;
            isValid = false;
        }
        if (!fields.AccountName) {
            newErrors.AccountName = true;
            isValid = false;
        }
        if (!fields.AccountNumber) {
            newErrors.AccountNumber = true;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        const vendorUpdate_id = localStorage.getItem('employee_id');

        if (validateFields(fields, setErrors)) {
            setLoading(true);
            const payload = {
                id: vendorUpdate_id,
                emp_id: fields.SelectEmployName,
                account_type: fields.AccountType,
                account_name: fields.AccountName,
                account_number: fields.AccountNumber,
                bank_name: fields.BankName,
                mobile_number: fields.MobileNumber,
                ifce_code: fields.Ifcecode,
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}Account-update`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });

                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    setLoading(false);
                    // setTimeout(() => {
                    //     // navigate('/vendermanagement');
                    // }, 1000);
                    onSave();
                } else {
                    toast.error(response.data.message);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const handleSubmitVendor = () => {
        setLoading(true);
        handleSubmit();
    };

    return (
        <div id="addVendorModal">

            <Card>
                <Card.Header>
                    <Card.Title>Update Account</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Type</Form.Label>
                                    <Form.Select
                                        value={fields.AccountType}
                                        onChange={handleFieldChange('AccountType')}
                                        className={errors.AccountType ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Account Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            {fields.AccountType === 'Cash' && (
                                <>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="custom-label">Account Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Account Name"
                                                value={fields.AccountName}
                                                onChange={handleFieldChange('AccountName')}
                                                className={errors.AccountName ? 'is-invalid' : ''}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={6} xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="custom-label">Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile Number"
                                                value={fields.MobileNumber}
                                                onChange={handleFieldChange('MobileNumber')}
                                                className={errors.MobileNumber ? 'is-invalid' : ''}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}

                            {fields.AccountType === 'Online' && (
                                <>
                                    {Object.keys(initialFields).map((key, index) => (
                                        key !== 'SelectEmployName' && key !== 'AccountType' && (
                                            <Col lg={4} sm={6} xs={12} key={index}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="custom-label">
                                                        {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type={key === 'MobileNumber' || key === 'AccountNumber' ? 'number' : (key === 'Joining_date' ? 'date' : 'text')}
                                                        placeholder={key.replace(/([A-Z])/g, ' $1')}
                                                        value={fields[key]}
                                                        onChange={handleFieldChange(key)}
                                                        className={errors[key] ? 'is-invalid' : ''}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )
                                    ))}
                                </>
                            )}
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitVendor} disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Account-Update'}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>

            {/* <ToastContainer /> */}
        </div>
    );
};

export default VendorForm;
