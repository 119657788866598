import React, { useMemo, useState, useEffect } from 'react';
import Ordertakeprocessingtable from '../Vendorreporttable';
import { Row, Col, Container, Form, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';

export default function Ordertakeprocessing() {
  const title = "Order take processing";
  const title1 = "Shipping History";
  const token = localStorage.getItem('token');
  const [orderlist, setOrderList] = useState([]);
  const [shippinglist, setListShipping] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(1); // Default to 1 for "Yes"

  useEffect(() => {
    fetchOrderlist();
    fetchOrderlistShipping();
  }, []);

  const fetchOrderlist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-take-processing-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setOrderList(response.data.data || []);
    } catch (error) {
      console.error('Error fetching order list:', error);
    }
  };
  const fetchOrderlistShipping = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Order-take-shipping-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setListShipping(response.data.data || []);
    } catch (error) {
      console.error('Error fetching order list:', error);
    }
  };

  const VendorRecriveStatusChange = async (vendorId, newStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      });

      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_API_URL}Vendor-receive-shipping-status/${vendorId}`;

        const config = {
          method: 'post',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          data: {
            shipping_status: newStatus,
          },
        };

        const response = await axios(config);

        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchOrderlist();
          fetchOrderlistShipping();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status. Please try again later.");
    }
  };

  const Vendorproduct = useMemo(
    () => [
      {
        Header: 'Order Id',
        accessor: 'order_id',
      },
      {
        Header: 'Vendor Name',
        accessor: 'vendor_name',
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },
      {
        Header: 'Product Name',
        accessor: 'product_name',
      },
      {
        Header: 'Order Type',
        accessor: 'customer_type',
      },
      {
        Header: 'Qty',
        accessor: 'add_receive_product_raw_qty',
      },
      {
        Header: 'Amount',
        accessor: 'add_receive_product_raw_amount',
      },
      {
        Header: 'Order Date',
        accessor: 'order_date',
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
      },
      {
        Header: 'Actual Date',
        accessor: 'add_receive_product_raw_date',
      },
      {
        Header: 'Receive',
        accessor: (row) => (
          <span style={{ color: row.add_receive_product_raw_status === 1 ? 'green' : 'red' }}>
            {row.add_receive_product_raw_status === 1 ? 'Yes' : 'No'}
          </span>
        ),
      },
      {
        Header: 'Send To Client',
        accessor: 'shipping_status',
        Cell: ({ cell: { value }, row }) => (
          value !== undefined ? (
            <tbody>
              <tr>
                <td style={{ color: value === 1 ? 'green' : 'red' }}>
                  <select
                    className='form-select w-auto noborder'
                    style={{ color: value === 1 ? 'green' : 'red' }}
                    defaultValue={value === 1 ? 'Delivered' : 'Panding'}
                    onChange={(e) => {
                      const newStatus = e.target.value === 'Delivered' ? 1 : 0;
                      VendorRecriveStatusChange(row.original.add_receive_product_raw_id, newStatus);
                    }}
                  >
                    <option value="Delivered" className='text-success'>Delivered</option>
                    <option value="Panding" className='text-danger'>Pending</option>
                  </select>
                </td>
              </tr>
            </tbody>
          ) : null
        ),
      },
    ],
    []
  );
  const Shippingproduct = useMemo(
    () => [
      {
        Header: 'Order Id',
        accessor: 'order_id',
      },
      {
        Header: 'Vendor Name',
        accessor: 'vendor_name',
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },
      {
        Header: 'Product Name',
        accessor: 'product_name',
      },

      {
        Header: 'Qty',
        accessor: 'add_receive_product_raw_qty',
      },
      {
        Header: 'Amount',
        accessor: 'add_receive_product_raw_amount',
      },

      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
      },


      {
        Header: 'Send To Client',
        accessor: 'shipping_status',
        Cell: ({ cell: { value }, row }) => (
          value !== undefined ? (
            <tbody>
              <tr>
                <td style={{ color: value === 1 ? 'green' : 'red' }}>
                  <select
                    className='form-select w-auto noborder'
                    style={{ color: value === 1 ? 'green' : 'red' }}
                    defaultValue={value === 1 ? 'Delivered' : 'Panding'}
                    onChange={(e) => {
                      const newStatus = e.target.value === 'Delivered' ? 1 : 0;
                      VendorRecriveStatusChange(row.original.add_receive_product_raw_id, newStatus);
                    }}
                  >
                    <option value="Delivered" className='text-success'>Delivered</option>
                    <option value="Panding" className='text-danger'>Pending</option>
                  </select>
                </td>
              </tr>
            </tbody>
          ) : null
        ),
      },
    ],
    []
  );

  const handleStatusChange = (e) => {
    setSelectedStatus(parseInt(e.target.value)); // Convert to integer
  };

  const filteredOrderList = orderlist.filter(item => item.add_receive_product_raw_status === selectedStatus);

  return (
    <>
      <ToastContainer />
      <div className='justify-content-center processing'>
        <div className='text-center'>
          <Row>
            <Col md={12} xs={12} lg={12}>
              <Form.Select onChange={handleStatusChange} value={selectedStatus} style={{ border: '2px solid brown' }}>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </Form.Select>
            </Col>
          </Row>
        </div>
      </div>
      <Ordertakeprocessingtable title={title} columns={Vendorproduct} data={filteredOrderList} />
      <hr></hr>
      <Ordertakeprocessingtable title={title1} columns={Shippingproduct} data={shippinglist} />
      {/* <ToastContainer /> */}
    </>
  );
}
