import React, { useState, useEffect } from 'react';
import { FaSearch, FaPlus, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import 'select2';
import { Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Swal from 'sweetalert2';
import nodata from '../assets/img/nodata.svg'
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


export default function Attribute() {
    const [showEditModal, setShowEditModal] = useState(false);
    const [data, setData] = useState([]);
    const [loadinglist, setLoadinglist] = useState(false);
    const [viewdata, setViewdata] = useState([]);
    const [editnameError, setEditNameError] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    // const onInputChangeEditName = (e) => {
    //     setEditname(e.target.value);
    //     setEditNameError(false);
    // };

    useEffect(() => {
        productlist();
    }, []);


    // const productlist = async () => {
    //     setLoadinglist(true);
    //     try {
    //         let url = `${process.env.REACT_APP_API_URL}product-list`;
    //         const config = {
    //             method: 'get',
    //             url: url,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-access-token': token,
    //             },
    //         };
    //         const response = await axios(config);
    //         setData(response.data.data);

    //         // Calculate total pages based on the fetched data
    //         setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
    //     } catch (error) {
    //         console.error("There was a problem with the fetch operation:", error);
    //     } finally {
    //         setLoadinglist(false);
    //     }
    // };

    const productlist = async () => {
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}product-list`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);

            // Filter the response data where type is 'add_product'
            const filteredData = response.data.data.filter(item => item.type === 'add_product');
            console.warn(response.data.data);
            // Set the filtered data
            setData(filteredData);

            // Calculate total pages based on the filtered data
            setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };



    const ProductView = async (id) => {
        setCurrentEditId(id);
        handleShowEditModal();
        setLoadinglist(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}product-view/${id}`;
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            };
            const response = await axios(config);
            setViewdata(response.data.data);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const AttributeDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (result.isConfirmed) {
                let url = `${process.env.REACT_APP_API_URL}product-delete/${id}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    productlist();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setLoadinglist(false);
        }
    };

    const ProductUpdate = async (id) => {
        localStorage.setItem("productUpdate_id", id);
        navigate('/Product-Update');
    };

    useEffect(() => {
        $('#mySelect2').select2();
        return () => {
            $('#mySelect2').select2('destroy');
        };
    }, []);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        const selectedItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(selectedItemsPerPage);

        // Recalculate total pages based on the new items per page value
        setTotalPages(Math.ceil(filteredData.length / selectedItemsPerPage));
    };

    const filteredData = data ? data.filter(item =>
        (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.category_name && item.category_name.toLowerCase().includes(searchQuery.toLowerCase()))
    ) : [];



    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const renderPagination = () => {
        const pageNumbers = [];
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const maxPageButtons = 10;
        const ellipsis = '...';

        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        // Adjust startPage and endPage if totalPages < maxPageButtons
        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.floor(maxPageButtons / 2)) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPageButtons / 2);
                endPage = currentPage + Math.floor(maxPageButtons / 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <>
                        <button onClick={() => handlePageChange(1)}>{1}</button>
                        {startPage > 2 && <span>{ellipsis}</span>}
                    </>
                )}
                {pageNumbers.map(number => (
                    <button key={number} onClick={() => handlePageChange(number)} className={number === currentPage ? 'active' : ''}>
                        {number}
                    </button>
                ))}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <span>{ellipsis}</span>}
                        <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                    </>
                )}
            </div>
        );
    };


    return (
        <div className="Category">
            <div className="page-header">
                <div className="add-item d-flex justify-content-between">
                    <div className="page-title">
                        <h4>Create Product</h4>
                        <h6>Create new product</h6>
                    </div>
                </div>
                <div className="button_add_row">
                    <Link to="/addproduct" className="btn btn-added d-flex gap-2">
                        <FaPlus />  Add Product
                    </Link>
                </div>
            </div>
            <div className="card table-list-card">
                <div className="card-header d-flex justify-content-between">
                    <div className="Category">
                        {/* Existing JSX code */}
                        <div>
                            <select onChange={handleItemsPerPageChange} className='form-select' value={itemsPerPage}>
                                <option value={10}>Items 10</option>
                                <option value={20}>Items 20</option>
                                <option value={30}>Items 30</option>
                                <option value={50}>Items 50</option>
                            </select>
                        </div>
                        {/* Existing JSX code */}
                    </div>
                    <div className="searchinputs d-flex">
                        <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />
                        <div className="btn d-flex align-items-center justify-content-center">
                            <FaSearch />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table-striped bordered hover table">
                            <thead>
                                <tr className="thead-dark">
                                    <th>Sr Number</th>
                                    <th>Product</th>
                                    <th>Category Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {loadinglist ? (
                                    <tr>
                                        <td colSpan="4" className='text-center'>
                                            <div>Loading...</div>
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedData && paginatedData.length > 0 ? (
                                        paginatedData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.category_name}</td>
                                                <td className="edit-delete-action justify-content-start d-flex gap-2">
                                                    <Link to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductView(item.id)}><FaEye /></Link>
                                                    <Button to="#" style={{ "--i": "#233882" }} className="p-2" onClick={() => ProductUpdate(item.id)}><FaEdit /></Button>
                                                    <Link to="#" style={{ "--i": "red" }} className="p-2" onClick={() => AttributeDelete(item.id)}><MdDelete /></Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className='text-center'>
                                                <img src={nodata} alt="nodata" width={250} />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        <span>Pages: <strong>{totalPages} </strong> </span>

                                        <span>Of <strong>{itemsPerPage}</strong></span>
                                    </td>
                                    <td className='d-flex justify-content-end'>
                                        {renderPagination()}

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <Modal show={showEditModal} size="lg" onHide={handleCloseEditModal} backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>View Details</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <h6 className="fw-bold">Product details</h6>
                        <table className="table">
                            <thead>
                                <tr className='thead-dark'>
                                    <th>Id</th>
                                    <th>Category Name</th>  
                                    <th>Name</th>
                                    <th>Unit</th>
                                    <th>Qty</th>
                                    <th>gst</th>
                                    <th>Cost Price</th>
                                    <th>Sale Price</th>
                                    <th>Manufacture</th>
                                </tr>
                            </thead>

                            <tbody>
                                {viewdata.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>{item.id}</td>
                                            <td>{item.category_name}</td>
                                            <td>{item.name}</td>
                                            <td>{item.pro_unit}</td>
                                            <td>{item.pro_qty}</td>
                                            <td>{item.gst}</td>
                                           {item.manufacture === 'Trading' ? (
                                                <>
                                                    <td>{item.cost_price}</td>
                                                    <td>{item.sale_price}</td>
                                                </>
                                            ) :
                                                <>
                                                    <td>{item.man_amount}</td>
                                                    <td>{item.sale_man_amount}</td>
                                                </>
                                            }
                                           
                                            <td>{item.manufacture}</td> 
                                        </tr>
                                        {item.manufacture === 'Trading' ? (
                                            <>
                                                <tr>
                                                    <td colSpan="6">
                                                        <h6 className="fw-bold">Attributes</h6>
                                                        <table className="table">
                                                            <thead className='thead-info'>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <th>Name</th>
                                                                    <th>Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.attributes.map(attr => (
                                                                    <tr key={attr.id}>
                                                                        <td>{attr.id}</td>
                                                                        <td>{attr.name}</td>
                                                                        <td>{attr.value}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="6">
                                                        <h6 className="fw-bold">Row Materials</h6>
                                                        <table className="table">
                                                            <thead className='thead-primary'>
                                                                <tr>
                                                                    {/* <th>Id</th> */}
                                                                    <th>Name</th>
                                                                    <th>Qty</th>
                                                                    <th>Unit Cost Price</th>
                                                                    <th>Unit Sale Price</th>
                                                                    <th>Cost Price</th>
                                                                    <th>Sale Price</th>
                                                                    <th>Vendor Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.row_materials.map(material => (
                                                                    <tr key={material.id}>
                                                                        {/* <td>{material.id}</td> */}
                                                                        <td>{material.raw_name}</td>
                                                                        {/* <td>demo</td> */}
                                                                        <td>{material.qty}</td>
                                                                        <td>{material.u_cost_prize}</td>
                                                                        <td>{material.unit_sale_price}</td>
                                                                        <td>{material.Costprice}</td>
                                                                        <td>{material.Saleprice}</td>
                                                                        <td>{material.vender_Name}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : null}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>

            </Modal>
            <ToastContainer />
        </div>
    );
}

