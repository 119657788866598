import React, { useState, useEffect, useMemo } from 'react';
import { Card, Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import RecriveAdd from './Addmaterial';
import RecriveView from './MaterialRecriveView';
import AddmaterialUpdate from './AddmaterialUpdate';
import { FaEye, FaEdit, FaSearch } from "react-icons/fa";
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import DataTable from './MaterialReceiveTable';
import { Link } from 'react-router-dom';


export default function Addmaterial() {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [custumerlist, setCustumerlist] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('customer');
    const [selectedStatuslocation, setSelectedLocationStatus] = useState('');
    const [productlocation, setproductlocation] = useState([]);
    const [modalState, setModalState] = useState({
        recriveAdd: false,
        recriveView: false,
        recriveUpdate: false,
    });

    const columns = useMemo(() => {
        // Common columns for all statuses
        const commonColumns = [
            {
                Header: 'Sr. No.',
                accessor: 'id',
            },
            {
                Header: 'Vendor Name',
                accessor: 'vendor_name',
            },
            {
                Header: 'Slip No',
                accessor: 'slip_no',
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ value }) => formatDate(value),
            },
            {
                Header: 'Gst',
                accessor: 'gst',
            },
            {
                Header: 'Price',
                accessor: 'total_cost',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div>
                        <Button variant="secondary" className="ms-2">
                            <FaEye onClick={() => handleView(row.original.id)} />
                        </Button>
                        <Button
                            style={{ backgroundColor: "#233882", borderColor: "#233882" }}
                            className="ms-2"
                            onClick={() => handleUpdate(row.original.id)}
                        >
                            <FaEdit />
                        </Button>
                        <Button
                            style={{ backgroundColor: "#d9534f", borderColor: "#d9534f" }}
                            className="ms-2"
                            onClick={() => handleDelete(row.original.id)}
                        >
                            <MdDelete />
                        </Button>
                    </div>
                ),
            },
        ];

        // Conditional columns based on selectedStatus
        const conditionalColumn = selectedStatus === 'customer'
            ? {
                Header: 'Customer',
                accessor: 'customer_name',
            }
            : {
                Header: 'Company',
                accessor: 'company_name',
            };

        return [...commonColumns.slice(0, 1), conditionalColumn, ...commonColumns.slice(1)];
    }, [selectedStatus]);


    useEffect(() => {
        if (!modalState.recriveAdd, !modalState.recriveUpdate) {
            fetchCustumer();
        }
    }, [token, modalState.recriveAdd, modalState.recriveUpdate]);
    const fetchCustumer = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}material-store-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setCustumerlist(response.data.data || []);
            // console.warn(response.data.data)
        } catch (error) {
            console.error('Error fetching Vendor:', error);
        }
    };

    useEffect(() => {
        const fetchCategories1 = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}Receive-stock-location-list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                });
                setproductlocation(response.data.data || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to fetch categories.');
            }
        };
        fetchCategories1();
    }, [token]);


    function formatDate(dateString) {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }
    const handleDelete = async (order) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });
            if (result.isConfirmed) {
                let url = `${process.env.REACT_APP_API_URL}material-delete/${order}`;
                const config = {
                    method: 'get',
                    url: url,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token,
                    },
                };
                const response = await axios(config);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    fetchCustumer();
                } else {
                    toast.error(response.data.message);
                }
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            // setLoadinglist(false);
        }
    };
    const handleView = (order) => {
        localStorage.setItem('met_receive', order);
        setModalState({ ...modalState, recriveView: true })
    }
    const handleUpdate = (order) => {
        localStorage.setItem('met_receive_update', order);
        setModalState({ ...modalState, recriveUpdate: true })
    }

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value); // No need to parseInt, keep it as a string
    };
    const handleStatusLocationChange = (e) => {
        setSelectedLocationStatus(e.target.value); // No need to parseInt, keep it as a string
    };

    const filteredOrderList = custumerlist
        .filter(item => !selectedStatus || item.met_type === selectedStatus)
        .filter(item => !selectedStatuslocation || item.receive_location_id === selectedStatuslocation);


    return (
        <>
        <div className='d-flex justify-content-between align-items-center gap-2 bg-white p-3 mb-2'>
        <h5 className="mb-0">Materials</h5>

                   <div className='d-flex gap-2'>
                   <Row>
                        <Col md={12} xs={12} lg={12} className=''>
                            <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '1px solid #8080802' }}>
                                <option value=''>Receive Type</option>
                                <option value="customer">Customer</option>
                                <option value="company">Company</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12} lg={12}>
                            <Form.Select aria-label="Default select example" onChange={handleStatusLocationChange} value={selectedStatuslocation} style={{ border: '1px solid #8080802' }}>
                                <option value="">Select Location</option>
                                {productlocation.map((vendor) => (
                                    <option key={vendor.id} value={vendor.id}>
                                        {vendor.product_stock_location_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                   </div>
                    <div className='d-flex justify-content-between mb-2 gap-2'>
                    <Link to="/Receive-Materials-Stock" className="btn btn-secondary" onClick={() => setModalState({ ...modalState, recriveAdd: true })}>  Stock Material</Link>
                    <Button type="button" className="btn btn-secondary" onClick={() => setModalState({ ...modalState, recriveAdd: true })}>  Add Receive</Button>
                    {/* <Button type="button" className="btn-added" onClick={goBack}> <FaArrowLeftLong style={{ paddingRight: '3px' }} />  Back</Button> */}
                </div>
                </div>
          
        
          
            <DataTable data={filteredOrderList} columns={columns} />
            <Modal
                size="xl"
                show={modalState.recriveAdd}
                onHide={() => setModalState({ ...modalState, recriveAdd: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "gray" }} className="card-title">Add Receive</h5>
                </Modal.Header>

                <Modal.Body closeButton>
                    <RecriveAdd onSave={() => { setModalState({ ...modalState, recriveAdd: false }); }} />
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={modalState.recriveView}
                onHide={() => setModalState({ ...modalState, recriveView: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "gray" }} className="card-title">Material Details</h5>
                </Modal.Header>

                <Modal.Body closeButton>
                    <RecriveView onSave={() => { setModalState({ ...modalState, recriveView: false }); }} />
                </Modal.Body>
            </Modal>
            <Modal
                size="xl"
                show={modalState.recriveUpdate}
                onHide={() => setModalState({ ...modalState, recriveUpdate: false })}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static" 
          keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 style={{ color: "gray" }} className="card-title">Material Receive Update</h5>
                </Modal.Header>

                <Modal.Body closeButton>
                    <AddmaterialUpdate onSave={() => { setModalState({ ...modalState, recriveUpdate: false }); }} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </>
    );
}
