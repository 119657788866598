import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const useFormFields = (initialFields) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(
        Object.keys(initialFields).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );

    const handleFieldChange = (key) => (e) => {
        let value = e.target.value;
        setFields({ ...fields, [key]: value });
        setErrors({ ...errors, [key]: false });
    };

    return { fields, errors, handleFieldChange, setFields, setErrors };
};

const validateFields = (fields, setErrors) => {
    const newErrors = {};
    let isValid = true;

    if (!fields.AccountType) {
        newErrors.AccountType = true;
        isValid = false;
    }
    if (!fields.AccountName) {
        newErrors.AccountName = true;
        isValid = false;
    }
    if (!fields.AccountToName) {
        newErrors.AccountToName = true;
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
};

const VendorForm = ({ onSave }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    const initialFields = {
        AccountToName: '',
        AccountType: '',
        AccountName: '',
        Amount: '',
        Date: '',
    };

    const { fields, errors, handleFieldChange, setFields, setErrors } = useFormFields(initialFields);
    const [loading, setLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);

    const handleSubmit = async () => {
        const payload = {
            account_type: fields.AccountType,
            account_id: fields.AccountName,
            account_to_id: fields.AccountToName,
            amount: fields.Amount,
            date: fields.Date,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Amount-transfer`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });

            if (response.data.status === 200) {
                toast.success(response.data.message);
                setFields(initialFields);
                onSave();
                setTimeout(() => {
                    navigate('/Account-list');
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitEmployee = () => {
        if (validateFields(fields, setErrors)) {
            setLoading(true);
            handleSubmit();
        }
    };

    useEffect(() => {
        fetchEmployeeList();
    }, []);

    const fetchEmployeeList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-list`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setEmployeeList(response.data.data || []);
        } catch (error) {
            console.error('Error fetching employee list:', error);
        }
    };

    return (
        <div id="addVendorModal">
            <Card>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Type</Form.Label>
                                    <Form.Select
                                        value={fields.AccountType}
                                        onChange={handleFieldChange('AccountType')}
                                        className={errors.AccountType ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select Account Type</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account Name</Form.Label>
                                    <Form.Select
                                        value={fields.AccountName}
                                        onChange={handleFieldChange('AccountName')}
                                        className={errors.AccountName ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select</option>
                                        {employeeList.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Account To Account </Form.Label>
                                    <Form.Select
                                        value={fields.AccountToName}
                                        onChange={handleFieldChange('AccountToName')}
                                        className={errors.AccountToName ? 'is-invalid' : ''}
                                    >
                                        <option value="">Select</option>
                                        {employeeList.map((vendor) => (
                                            <option key={vendor.id} value={vendor.id}>
                                                {vendor.account_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        value={fields.Amount}
                                        onChange={handleFieldChange('Amount')}
                                        className={errors.Amount ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={4} sm={6} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="custom-label">Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        value={fields.Date  || new Date().toISOString().split("T")[0]}
                                        onFocus={(e) => e.target.showPicker()} 
                                        onChange={handleFieldChange('Date')}
                                        className={errors.Date ? 'is-invalid' : ''}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="btn btn-submit" onClick={handleSubmitEmployee} disabled={loading}>
                            {loading ? 'Submitting...' : 'Create'}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default VendorForm;
