import react from 'react';
import mail from '../assets/img/mail.svg'
import facebook from '../assets/img/facebook-logo.svg'
import applelogo from '../assets/img/apple-logo.svg'
import google from '../assets/img/google.png'
import {Link} from 'react-router-dom'
export default function Forgetpassword(){
 return(
    <>
    <div className="main-wrapper">
  <div className="account-content">
    <div className="login-wrapper forgot-pass-wrap bg-img">
      <div className="login-content">
        <form action="signin.html">
          <div className="login-userset">
            <div className="login-logo logo-normal">
              <img src="assets/img/logo.png" alt="img" />
            </div>
            <Link to="index.html" className="login-logo logo-white">
              <img src="assets/img/logo-white.png" alt="" />
           </Link>
            <div className="login-userheading">
              <h3>Forgot password?</h3>
              <h4>
                If you forgot your password, well, then we’ll email you
                instructions to reset your password.
              </h4>
            </div>
            <div className="form-login">
              <label>Email</label>
              <div className="form-addons">
                <input type="email" className="form-control" />
                <img src={mail} alt="img" />
              </div>
            </div>
            <div className="form-login">
              <button type="submit" className="btn btn-login">
                Sign Up
              </button>
            </div>
            <div className="signinform text-center">
              <h4>
                Return to
                <Link to="/" className="hover-a">
                  
                  login
               </Link>
              </h4>
            </div>
            <div className="form-setlogin or-text">
              <h4>OR</h4>
            </div>
            <div className="form-sociallink">
              <ul className="d-flex justify-content-center">
                <li>
                  <Link to="javascript:void(0);" className="facebook-logo">
                    <img
                      src={facebook}
                      alt="Facebook"
                    />
                 </Link>
                </li>
                <li>
                  <Link to="javascript:void(0);">
                    <img src={google} alt="Google" />
                 </Link>
                </li>
                <li>
                  <Link to="javascript:void(0);" className="apple-logo">
                    <img src={applelogo} alt="Apple" />
                 </Link>
                </li>
              </ul>
            </div>
         
          </div>
        </form>
      </div>
    </div>
  </div>
 
</div>

    </>
 );
}